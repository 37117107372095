import { useTheme } from "@/store/hooks"

import { SilverSVG } from "./styled"

export default function RankSilverThree() {
  const theme = useTheme()

  return (
    <SilverSVG
      width="158"
      height="48"
      viewBox="0 0 158 48"
      xmlns="http://www.w3.org/2000/svg"
      $theme={theme}
    >
      <rect width="158" height="48" rx="8" />
      <path d="M52.4617 33.1318C52.2251 33.1318 51.9884 33.0584 51.7851 32.9135L46.9988 29.4821L42.2142 32.9135C41.8059 33.2051 41.2593 33.2051 40.8543 32.9084C40.4494 32.6151 40.2794 32.0935 40.4294 31.6186L42.2142 25.859L37.4729 22.5176C37.0696 22.221 36.903 21.6977 37.0563 21.2211C37.2113 20.7461 37.6529 20.4228 38.1545 20.4195L44.0274 20.4111L45.8972 14.7949C46.0556 14.32 46.4989 14 47.0005 14C47.5004 14 47.9454 14.32 48.1037 14.7949L49.9419 20.4111L55.8448 20.4195C56.3464 20.4228 56.7897 20.7461 56.943 21.2211C57.098 21.6977 56.9297 22.2193 56.5264 22.5176L51.7834 25.859L53.5683 31.6186C53.7216 32.0952 53.5483 32.6151 53.145 32.9084C52.94 33.0584 52.7 33.1318 52.4617 33.1318Z" />
      <path d="M84.4617 33.1318C84.2251 33.1318 83.9884 33.0584 83.7851 32.9135L78.9988 29.4821L74.2142 32.9135C73.8059 33.2051 73.2593 33.2051 72.8543 32.9084C72.4494 32.6151 72.2794 32.0935 72.4294 31.6186L74.2142 25.859L69.4729 22.5176C69.0696 22.221 68.903 21.6977 69.0563 21.2211C69.2113 20.7461 69.6529 20.4228 70.1545 20.4195L76.0274 20.4111L77.8972 14.7949C78.0556 14.32 78.4989 14 79.0005 14C79.5004 14 79.9454 14.32 80.1037 14.7949L81.9419 20.4111L87.8448 20.4195C88.3464 20.4228 88.7897 20.7461 88.943 21.2211C89.098 21.6977 88.9297 22.2193 88.5264 22.5176L83.7834 25.859L85.5683 31.6186C85.7216 32.0952 85.5483 32.6151 85.145 32.9084C84.94 33.0584 84.7 33.1318 84.4617 33.1318Z" />
      <path d="M116.462 33.1318C116.225 33.1318 115.988 33.0584 115.785 32.9135L110.999 29.4821L106.214 32.9135C105.806 33.2051 105.259 33.2051 104.854 32.9084C104.449 32.6151 104.279 32.0935 104.429 31.6186L106.214 25.859L101.473 22.5176C101.07 22.221 100.903 21.6977 101.056 21.2211C101.211 20.7461 101.653 20.4228 102.155 20.4195L108.027 20.4111L109.897 14.7949C110.056 14.32 110.499 14 111 14C111.5 14 111.945 14.32 112.104 14.7949L113.942 20.4111L119.845 20.4195C120.346 20.4228 120.79 20.7461 120.943 21.2211C121.098 21.6977 120.93 22.2193 120.526 22.5176L115.783 25.859L117.568 31.6186C117.722 32.0952 117.548 32.6151 117.145 32.9084C116.94 33.0584 116.7 33.1318 116.462 33.1318Z" />
    </SilverSVG>
  )
}
