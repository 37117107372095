export default function DefaultAvatar({ width = 36, height = 36 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      style={{
        borderRadius: "50%",
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <rect width="100" height="100" fill="#0D0E0F" />
        <g filter="url(#filter0_d)">
          <path
            d="M73.6111 75V68.0556C73.6111 63.4528 69.8805 59.7222 65.2777 59.7222H43.0555C38.4541 59.7222 34.7222 63.4528 34.7222 68.0556V75H73.6111Z"
            fill="#FA743E"
          />
          <path
            d="M48.1111 59.8695C48.1111 61.1111 51.3889 62.6375 54.1667 62.6375C56.9445 62.6375 60.2209 61.1111 60.2209 59.8708V54.5695H48.1097V59.8695H48.1111Z"
            fill="#FFDC5D"
          />
          <path
            d="M48.1 57.1125C49.7889 59.0208 51.8834 59.5375 54.1611 59.5375C56.4375 59.5375 58.5306 59.0194 60.2208 57.1125V52.2639H48.0986V57.1125H48.1Z"
            fill="#F9CA55"
          />
          <path
            d="M59.3486 25.7375C56.675 24.8722 50.3833 25.1083 48.8097 27.1528C44.7208 27.2319 39.9236 30.9278 39.2945 35.8028C38.6722 40.6292 40.0597 42.8694 40.5528 46.4986C41.1125 50.6097 43.4236 51.925 45.2708 52.475C47.9292 55.9875 50.7556 55.8375 55.5014 55.8375C64.7681 55.8375 69.182 49.6375 69.5722 39.1056C69.8083 32.7375 66.0708 27.9125 59.3486 25.7375V25.7375Z"
            fill="#5C31A3"
          />
          <path
            d="M64.6487 39.557C63.7514 38.3153 62.6042 37.3153 60.0875 36.9625C61.032 37.3945 61.9362 38.8889 62.0542 39.7153C62.1723 40.5417 62.2903 41.2097 61.5431 40.3833C58.55 37.075 55.2903 38.3778 52.0612 36.357C49.8056 34.9458 49.1181 33.3833 49.1181 33.3833C49.1181 33.3833 48.8431 35.4667 45.4223 37.5903C44.4306 38.2056 43.2473 39.5764 42.5917 41.6014C42.1195 43.057 42.2667 44.3542 42.2667 46.5708C42.2667 53.0431 47.6014 58.4847 54.1806 58.4847C60.7598 58.4847 66.0945 52.9945 66.0945 46.5708C66.0917 42.5458 65.6709 40.9722 64.6487 39.557V39.557Z"
            fill="#FFDC5D"
          />
          <path
            d="M70.8333 73.6111C70.8333 74.3792 70.2125 75 69.4444 75H55.5555C54.7875 75 54.1666 74.3792 54.1666 73.6111C54.1666 72.8431 54.7875 72.2222 55.5555 72.2222H69.4444C70.2125 72.2222 70.8333 72.8431 70.8333 73.6111Z"
            fill="#E1E8ED"
          />
          <path
            d="M53.1112 55.5556H30.2209C28.1042 55.5556 25.3903 56.6264 26.7792 60.4597L31.0459 72.2306C31.0473 72.2306 31.5973 75 34.7223 75H62.5001L56.9445 59.3889C56.3362 57.5528 55.2292 55.5556 53.1112 55.5556V55.5556Z"
            fill="#E1E8ED"
          />
          <path
            d="M51.7223 55.5556H28.832C26.7153 55.5556 24.0014 56.6264 25.3903 60.4597L29.657 72.2306C29.6584 72.2306 30.2084 75 33.3334 75H61.1112L55.5556 59.3889C54.9473 57.5528 53.8403 55.5556 51.7223 55.5556V55.5556Z"
            fill="#99AAB5"
          />
          <path
            d="M44.4708 65.6708C45.1986 67.8542 44.6083 70.0181 43.1528 70.5028C41.6972 70.9889 39.9278 69.6125 39.2 67.4292C38.4722 65.2458 39.0625 63.0819 40.5181 62.5972C41.9736 62.1125 43.7444 63.4875 44.4708 65.6708ZM51.3889 59.375H29.1667C28.5917 59.375 28.125 58.9083 28.125 58.3333C28.125 57.7583 28.5917 57.2917 29.1667 57.2917H51.3889C51.9639 57.2917 52.4306 57.7583 52.4306 58.3333C52.4306 58.9083 51.9639 59.375 51.3889 59.375Z"
            fill="#E1E8ED"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="-35"
          y="-31"
          width="170"
          height="170"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="30" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.616667 0 0 0 0 0.243058 0 0 0 0 0.0925 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <clipPath id="clip0">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
