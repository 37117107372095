export default function EmailIcon() {
  return (
    <svg fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.833 3.333a2.5 2.5 0 012.5 2.5v8.334a2.5 2.5 0 01-2.5 2.5H4.167a2.5 2.5 0 01-2.5-2.5V5.833a2.5 2.5 0 012.5-2.5h11.666zm.834 2.774l-6.118 5.354a.833.833 0 01-1.016.063l-.082-.063-6.118-5.353v8.059c0 .46.373.833.834.833h11.666c.46 0 .834-.373.834-.833v-8.06zM15.4 5H4.598L10 9.726 15.4 5z"
        fillRule="evenodd"
      />
    </svg>
  )
}
