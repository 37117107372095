export default function DetectiveIcon({ width = 36, height = 36 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33 36V35C33 31.687 30.313 29 27 29H9C5.687 29 3 31.687 3 35V36H33Z"
        fill="#66757F"
      />
      <path
        d="M12 27.482C13.672 29.057 15.746 30 18 30C20.254 30 22.327 29.056 24 27.482V26H12V27.482Z"
        fill="#EF9645"
      />
      <path
        d="M26.7492 20.4351C27.9372 20.6431 29.3682 20.5641 29.1652 21.3521C28.6862 23.2061 26.5612 22.5191 26.1862 22.5401C25.8112 22.5601 26.7492 20.4351 26.7492 20.4351Z"
        fill="#66757F"
      />
      <path
        d="M27.0631 20.645C28.9381 20.895 29.6041 21.061 28.2291 21.603C27.4571 21.908 25.9861 26.406 24.8981 25.721C23.8111 25.036 27.0631 20.645 27.0631 20.645Z"
        fill="#292F33"
      />
      <path
        d="M9.2557 20.4351C8.0677 20.6431 6.6367 20.5641 6.8397 21.3521C7.3187 23.2061 9.4437 22.5191 9.8187 22.5401C10.1937 22.5601 9.2557 20.4351 9.2557 20.4351Z"
        fill="#66757F"
      />
      <path
        d="M8.94323 20.645C7.06823 20.895 6.40223 21.061 7.77723 21.603C8.54923 21.908 10.0202 26.406 11.1082 25.721C12.1962 25.036 8.94323 20.645 8.94323 20.645Z"
        fill="#292F33"
      />
      <path
        d="M8.0542 11.0308C6.1012 11.0308 5.7492 14.1948 7.3902 14.6248C7.3902 14.6248 6.0232 17.9448 9.3432 17.9448C8.7962 16.2648 7.7812 13.5308 8.5622 11.5388L8.0542 11.0308ZM27.9422 11.0308C29.8952 11.0308 30.2472 14.1948 28.6062 14.6248C28.6062 14.6248 29.9732 17.9448 26.6532 17.9448C27.2002 16.2648 28.2152 13.5308 27.4342 11.5388"
        fill="#FFAC33"
      />
      <path
        d="M18 28C23.5228 28 28 22.4036 28 15.5C28 8.59644 23.5228 3 18 3C12.4772 3 8 8.59644 8 15.5C8 22.4036 12.4772 28 18 28Z"
        fill="#FFDC5D"
      />
      <path
        d="M14 17C13.448 17 13 16.552 13 16V15C13 14.448 13.448 14 14 14C14.552 14 15 14.448 15 15V16C15 16.552 14.552 17 14 17ZM22 17C21.447 17 21 16.552 21 16V15C21 14.448 21.447 14 22 14C22.553 14 23 14.448 23 15V16C23 16.552 22.553 17 22 17Z"
        fill="#662113"
      />
      <path
        d="M19 20.5C19 20.776 18.776 21 18.5 21H17.5C17.224 21 17 20.776 17 20.5C17 20.224 17.224 20 17.5 20H18.5C18.776 20 19 20.224 19 20.5Z"
        fill="#C1694F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.65775 14.7879C7.80575 14.9349 8.54575 15.3789 8.69375 15.8219C8.84175 16.2649 9.13875 18.7759 10.0268 19.5149C10.9428 20.2769 14.3967 19.9929 15.0587 19.6639C16.5387 18.9259 16.7208 16.8659 16.9828 15.8219C17.1308 15.2309 18.0188 15.2309 18.0188 15.2309C18.0188 15.2309 18.9068 15.2309 19.0548 15.8219C19.3168 16.8659 19.4988 18.9259 20.9788 19.6629C21.6408 19.9929 25.0947 20.2769 26.0127 19.5159C26.8997 18.7769 27.1957 16.2659 27.3438 15.8219C27.4897 15.3789 28.2318 14.9359 28.3787 14.7879C28.5268 14.6399 28.5268 14.0489 28.3787 13.9009C28.0828 13.6059 24.5907 13.3419 20.8308 13.7529C20.0808 13.8349 19.7957 14.0479 18.0188 14.0479C16.2428 14.0479 15.9568 13.8339 15.2068 13.7529C11.4478 13.3419 7.95475 13.6049 7.65875 13.9009C7.50975 14.0489 7.50975 14.6409 7.65775 14.7879Z"
        fill="#292F33"
      />
      <path
        d="M7.85902 8.39517C7.85902 8.39517 9.21802 -0.505833 13.791 0.023167C17.303 0.429167 18.681 0.848167 21.624 0.120167C23.571 -0.361833 25.689 1.25617 26.966 4.49917C27.782 6.56717 28.19 8.54017 28.19 8.54017C28.19 8.54017 32.128 8.15517 32.355 10.2722C32.583 12.3892 28.001 14.9882 16.466 14.9882C10.001 14.9872 3.33102 12.6302 3.01402 10.6572C2.69702 8.68417 7.85902 8.39517 7.85902 8.39517Z"
        fill="#66757F"
      />
      <path
        d="M8.12601 7.1499C8.12601 7.1499 7.85601 8.2539 7.72001 9.0209C7.58401 9.7889 7.94601 10.3169 10.425 10.8449C13.712 11.5449 21.104 11.5369 25.483 10.4619C27.242 10.0299 28.369 9.7419 28.234 8.8789C28.067 7.8109 28.038 7.8129 27.693 6.6709C27.693 6.6709 26.216 7.1729 24.266 7.6309C21.606 8.2549 14.302 8.5419 10.785 7.7749C8.91101 7.3649 8.12601 7.1499 8.12601 7.1499ZM7.99001 21.1029C7.63601 21.2479 10.911 22.4809 15.47 22.5609C20.241 22.6449 21.704 22.9509 20.616 24.0199C19.47 25.1449 19.764 26.9139 19.845 27.4379C19.926 27.9619 21.892 29.3539 22.053 29.9979C22.214 30.6429 20.824 35.9589 20.824 35.9589L12.095 35.7069C9.53001 26.8629 9.21201 27.2059 7.99001 22.1029C7.74901 21.0949 7.99001 21.1029 7.99001 21.1029Z"
        fill="#292F33"
      />
      <path
        d="M6.99022 21.144C6.63622 21.29 9.91122 22.522 14.4702 22.602C19.2412 22.686 20.7042 22.992 19.6162 24.061C18.4702 25.186 18.9522 26.955 19.0332 27.479C19.1142 28.003 20.8922 29.395 21.0542 30.04C21.2142 30.684 19.8232 36 19.8232 36L11.0942 35.748C8.52922 26.904 8.21122 27.247 6.98922 22.144C6.74922 21.136 6.99022 21.144 6.99022 21.144Z"
        fill="#66757F"
      />
      <path
        d="M28.0515 21.103C28.4055 21.248 25.1305 22.481 20.5725 22.561C15.8015 22.645 14.3385 22.951 15.4265 24.02C16.5725 25.145 18.4025 26.912 18.3225 27.436C18.2415 27.96 14.1505 29.354 13.9895 29.998C13.8285 30.643 15.2185 35.959 15.2185 35.959L23.9475 35.707C26.5125 26.863 26.8305 27.206 28.0515 22.103C28.2925 21.095 28.0515 21.103 28.0515 21.103Z"
        fill="#292F33"
      />
      <path
        d="M28.9578 21.103C29.3118 21.248 26.0368 22.481 21.4788 22.561C16.7078 22.645 15.2448 22.951 16.3328 24.02C17.4788 25.145 19.3098 26.912 19.2288 27.436C19.1478 27.96 15.0568 29.354 14.8958 29.998C14.7348 30.643 16.1248 35.959 16.1248 35.959L24.7818 35.969C27.3468 27.125 27.7368 27.206 28.9588 22.103C29.1988 21.095 28.9578 21.103 28.9578 21.103Z"
        fill="#66757F"
      />
    </svg>
  )
}
