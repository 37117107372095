export default function Coffin({ width = 20, height = 20 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2223 6.66663L16.4378 12.3527L4.08505 20L2.77783 15.5555L17.2223 6.66663Z"
        fill="#C1694F"
      ></path>
      <path
        d="M0.555664 12.2222V16.4705L4.08511 19.9999V15.5555L0.555664 12.2222Z"
        fill="#662113"
      ></path>
      <path
        d="M18.8891 2.77783V7.05894L16.438 12.3528V8.33339L17.2224 5.00005L18.8891 2.77783Z"
        fill="#A04E3C"
      ></path>
      <path
        d="M18.889 2.77778L16.4379 8.23556L4.08511 15.8822L0.555664 12.2222L9.96733 1.17667L15.8496 0L18.889 2.77778Z"
        fill="#D99E82"
      ></path>
      <path
        d="M6.66737 16.6666C6.48792 16.6666 6.31181 16.5799 6.20459 16.4194C6.03459 16.1638 6.10348 15.8194 6.35848 15.6488L8.02515 14.5377C8.28015 14.3671 8.62515 14.436 8.7957 14.6916C8.9657 14.9471 8.89681 15.2916 8.64181 15.4621L6.97515 16.5732C6.88015 16.636 6.77292 16.6666 6.66737 16.6666ZM12.7785 12.7777C12.599 12.7777 12.4224 12.691 12.3157 12.5305C12.1451 12.2749 12.214 11.9305 12.4696 11.7599L14.1363 10.6488C14.3924 10.4788 14.7363 10.5477 14.9068 10.8027C15.0774 11.0582 15.0085 11.4027 14.7529 11.5732L13.0863 12.6844C12.9913 12.7471 12.884 12.7777 12.7785 12.7777Z"
        fill="#FFAC33"
      ></path>
      <path
        d="M4.9997 13.8889C4.85637 13.8889 4.71415 13.8333 4.60637 13.7261L2.9397 12.0594C2.72748 11.8472 2.72193 11.5055 2.92748 11.2866L11.2608 2.39774C11.3441 2.30885 11.4547 2.24997 11.5747 2.22997L14.908 1.67441C15.083 1.64441 15.2647 1.70219 15.3919 1.82941L16.503 2.94052C16.6608 3.09774 16.7091 3.3333 16.6269 3.53997L15.5158 6.31774C15.4769 6.41552 15.4108 6.49997 15.3253 6.56163L5.32526 13.7839C5.22748 13.8544 5.11359 13.8889 4.9997 13.8889ZM4.10637 11.6544L5.05804 12.6061L14.5441 5.75497L15.4586 3.46663L14.8091 2.81663L11.943 3.29441L4.10637 11.6544Z"
        fill="#C1694F"
      ></path>
    </svg>
  )
}
