export default function SortMeLogo({ width = 101, height = 19, onClick }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 101 19"
      onClick={onClick}
      style={{
        gridArea: "logo",
        transition:
          "fill var(--transition-duration) var(--transition-function)",
        cursor: "pointer",
        fill: "var(--navbar-primary)",
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.31998 18.336C10.968 18.336 13.56 16.416 13.56 13.176C13.56 9.62402 10.704 8.73601 8.15998 7.96801C5.54398 7.17601 5.13598 6.64801 5.13598 5.88001C5.13598 5.20801 5.73598 4.60801 6.93598 4.60801C8.47198 4.60801 9.26398 5.35201 9.83998 6.55201L13.08 4.65601C11.856 2.18401 9.69598 0.864014 6.93598 0.864014C4.03198 0.864014 1.29598 2.73601 1.29598 5.97601C1.29598 9.19201 3.74398 10.392 6.23998 11.088C8.75998 11.784 9.71998 12.192 9.71998 13.224C9.71998 13.872 9.26398 14.592 7.43998 14.592C5.54398 14.592 4.51198 13.656 3.91198 12.264L0.599976 14.184C1.53598 16.656 3.79198 18.336 7.31998 18.336Z" />
      <path d="M23.7379 18.336C28.5619 18.336 32.4739 14.544 32.4739 9.60001C32.4739 4.65601 28.5619 0.864014 23.7379 0.864014C18.9138 0.864014 15.0019 4.65601 15.0019 9.60001C15.0019 14.544 18.9138 18.336 23.7379 18.336ZM23.7379 14.592C21.0019 14.592 18.8419 12.6 18.8419 9.60001C18.8419 6.60001 21.0019 4.60801 23.7379 4.60801C26.4739 4.60801 28.6339 6.60001 28.6339 9.60001C28.6339 12.6 26.4739 14.592 23.7379 14.592Z" />
      <path d="M43.8279 18H47.9559L44.3799 11.832C46.2279 10.896 47.4759 9.02401 47.4759 6.96001C47.4759 3.76801 44.9079 1.20001 41.7159 1.20001H34.9959V18H38.8359V12.48H40.6599L43.8279 18ZM38.8359 4.80001H41.7159C42.7719 4.80001 43.6359 5.73601 43.6359 6.96001C43.6359 8.18401 42.7719 9.12001 41.7159 9.12001H38.8359V4.80001Z" />
      <path d="M61.05 1.20001H48.57V4.89601H52.89V18H56.73V4.89601H61.05V1.20001Z" />
      <path d="M86.2509 1.20001H82.4109L77.8509 8.66401L73.2909 1.20001H69.4509V18H73.2909V8.23202L77.6349 15.36H78.0669L82.4109 8.23202V18H86.2509V1.20001Z" />
      <path d="M93.3384 14.304V11.328H99.3384V7.68002H93.3384V4.89601H99.9384V1.20001H89.4984V18H100.058V14.304H93.3384Z" />
    </svg>
  )
}
