export default function PartyPopperIcon({ width = 20, height = 20 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.45934 4.66C6.39711 4.72222 6.34989 4.79722 6.31045 4.87944L6.306 4.875L0.0748928 18.9117L0.0810039 18.9178C-0.0345517 19.1417 0.158782 19.5972 0.554893 19.9939C0.951004 20.39 1.40656 20.5833 1.63045 20.4678L1.636 20.4733L15.6727 14.2417L15.6682 14.2367C15.7499 14.1978 15.8249 14.1506 15.8877 14.0872C16.7554 13.2194 15.3482 10.4056 12.7454 7.80222C10.1416 5.19889 7.32767 3.79222 6.45934 4.66Z"
        fill="#DD2E44"
      />
      <path
        d="M7.22267 7.16667L0.231559 18.5589L0.0748928 18.9117L0.0810039 18.9178C-0.0345517 19.1417 0.158782 19.5972 0.554893 19.9939C0.683782 20.1228 0.817671 20.2206 0.948782 20.3033L9.44489 9.94445L7.22267 7.16667Z"
        fill="#EA596E"
      />
      <path
        d="M12.7842 7.75889C15.3786 10.3544 16.8192 13.1211 16.0003 13.9389C15.1819 14.7578 12.4153 13.3178 9.81915 10.7233C7.22415 8.12778 5.78415 5.36 6.60249 4.54167C7.42138 3.72333 10.188 5.16333 12.7842 7.75889Z"
        fill="#A0041E"
      />
      <path
        d="M10.3274 8.06055C10.2168 8.15 10.0724 8.19666 9.91958 8.18C9.43736 8.12778 9.03181 7.96 8.74792 7.695C8.44736 7.41444 8.29903 7.03778 8.33958 6.66055C8.4107 5.99833 9.07514 5.39055 10.2079 5.51277C10.6485 5.56 10.8451 5.41833 10.8518 5.35055C10.8596 5.28333 10.6979 5.10278 10.2574 5.055C9.77514 5.00278 9.36958 4.835 9.08514 4.57C8.78458 4.28944 8.6357 3.91278 8.67681 3.53555C8.74903 2.87333 9.41292 2.26555 10.5446 2.38833C10.8657 2.42278 11.0351 2.35666 11.1068 2.31389C11.164 2.27889 11.1868 2.24555 11.189 2.22611C11.1957 2.15889 11.0363 1.97833 10.5946 1.93055C10.2896 1.89722 10.0685 1.62389 10.1024 1.31833C10.1351 1.01333 10.4079 0.792775 10.714 0.826109C11.8457 0.947775 12.3657 1.68278 12.294 2.34555C12.2218 3.00889 11.5579 3.61555 10.4251 3.49389C10.104 3.45889 9.93625 3.52555 9.86403 3.56833C9.80681 3.60278 9.78347 3.63666 9.78125 3.65555C9.77403 3.72333 9.93458 3.90333 10.3763 3.95111C11.5079 4.07333 12.0279 4.80778 11.9563 5.47055C11.8846 6.13277 11.2207 6.74055 10.0885 6.61778C9.76736 6.58333 9.59847 6.65 9.52625 6.69222C9.46847 6.72778 9.44625 6.76111 9.44403 6.78C9.43681 6.84722 9.59736 7.02778 10.0385 7.07555C10.3429 7.10889 10.5646 7.38277 10.5307 7.68778C10.5151 7.84 10.4379 7.97166 10.3274 8.06055Z"
        fill="#AA8DD8"
      />
      <path
        d="M17.0338 13.1983C18.1299 12.8889 18.886 13.3778 19.066 14.0194C19.246 14.6606 18.856 15.4722 17.7604 15.7806C17.3326 15.9006 17.2043 16.105 17.2215 16.17C17.2404 16.2356 17.4576 16.3433 17.8843 16.2228C18.9799 15.9144 19.736 16.4033 19.916 17.0444C20.0971 17.6861 19.706 18.4967 18.6099 18.8056C18.1826 18.9256 18.0538 19.1306 18.0726 19.1956C18.091 19.2606 18.3076 19.3683 18.7349 19.2483C19.0293 19.1656 19.3371 19.3372 19.4199 19.6322C19.5021 19.9278 19.3304 20.2344 19.0349 20.3178C17.9399 20.6261 17.1832 20.1383 17.0021 19.4961C16.8221 18.855 17.2126 18.0444 18.3093 17.7356C18.7371 17.615 18.8654 17.4111 18.8465 17.3456C18.8288 17.2806 18.6121 17.1722 18.1854 17.2922C17.0888 17.6011 16.3332 17.1133 16.1526 16.4706C15.9721 15.8294 16.3626 15.0189 17.4588 14.7094C17.8854 14.59 18.0138 14.3844 17.996 14.32C17.9771 14.2544 17.761 14.1467 17.3338 14.2667C17.0382 14.35 16.7321 14.1778 16.6488 13.8828C16.566 13.5883 16.7382 13.2817 17.0338 13.1983Z"
        fill="#77B255"
      />
      <path
        d="M12.7787 11.7C12.6153 11.7 12.4542 11.6283 12.3442 11.4917C12.1526 11.2517 12.192 10.9022 12.4309 10.7106C12.552 10.6133 15.4409 8.34444 19.5237 8.92833C19.8276 8.97166 20.0387 9.25278 19.9953 9.55666C19.952 9.86 19.6731 10.0733 19.3665 10.0278C15.7592 9.51555 13.1515 11.5578 13.1259 11.5783C13.0226 11.6606 12.9003 11.7 12.7787 11.7Z"
        fill="#AA8DD8"
      />
      <path
        d="M3.19708 9.38889C3.14431 9.38889 3.09042 9.38111 3.03708 9.36556C2.7432 9.27722 2.57653 8.96778 2.66486 8.67389C3.29431 6.57778 3.86486 3.23278 3.16375 2.36056C3.08542 2.26167 2.96709 2.16445 2.69597 2.185C2.17486 2.225 2.22431 3.32444 2.22486 3.33556C2.2482 3.64167 2.0182 3.90833 1.71264 3.93111C1.40208 3.95 1.13986 3.72444 1.11708 3.41833C1.05986 2.65222 1.2982 1.17667 2.61264 1.07722C3.19931 1.03278 3.68653 1.23667 4.03042 1.66444C5.34764 3.30389 4.01042 8.05667 3.72931 8.99333C3.65708 9.23389 3.43597 9.38889 3.19708 9.38889Z"
        fill="#77B255"
      />
      <path
        d="M14.1663 6.61111C14.6266 6.61111 14.9997 6.23801 14.9997 5.77778C14.9997 5.31754 14.6266 4.94444 14.1663 4.94444C13.7061 4.94444 13.333 5.31754 13.333 5.77778C13.333 6.23801 13.7061 6.61111 14.1663 6.61111Z"
        fill="#5C913B"
      />
      <path
        d="M1.11111 11.6111C1.72476 11.6111 2.22222 11.1137 2.22222 10.5C2.22222 9.88635 1.72476 9.38889 1.11111 9.38889C0.497461 9.38889 0 9.88635 0 10.5C0 11.1137 0.497461 11.6111 1.11111 11.6111Z"
        fill="#9266CC"
      />
      <path
        d="M18.056 12.1667C18.5162 12.1667 18.8893 11.7936 18.8893 11.3333C18.8893 10.8731 18.5162 10.5 18.056 10.5C17.5958 10.5 17.2227 10.8731 17.2227 11.3333C17.2227 11.7936 17.5958 12.1667 18.056 12.1667Z"
        fill="#5C913B"
      />
      <path
        d="M13.056 18.8333C13.5162 18.8333 13.8893 18.4602 13.8893 18C13.8893 17.5398 13.5162 17.1667 13.056 17.1667C12.5958 17.1667 12.2227 17.5398 12.2227 18C12.2227 18.4602 12.5958 18.8333 13.056 18.8333Z"
        fill="#5C913B"
      />
      <path
        d="M15.5554 3.83333C16.1691 3.83333 16.6666 3.33587 16.6666 2.72222C16.6666 2.10857 16.1691 1.61111 15.5554 1.61111C14.9418 1.61111 14.4443 2.10857 14.4443 2.72222C14.4443 3.33587 14.9418 3.83333 15.5554 3.83333Z"
        fill="#FFCC4D"
      />
      <path
        d="M18.056 6.05556C18.5162 6.05556 18.8893 5.68246 18.8893 5.22222C18.8893 4.76199 18.5162 4.38889 18.056 4.38889C17.5958 4.38889 17.2227 4.76199 17.2227 5.22222C17.2227 5.68246 17.5958 6.05556 18.056 6.05556Z"
        fill="#FFCC4D"
      />
      <path
        d="M16.389 8.27778C16.8492 8.27778 17.2223 7.90468 17.2223 7.44444C17.2223 6.98421 16.8492 6.61111 16.389 6.61111C15.9288 6.61111 15.5557 6.98421 15.5557 7.44444C15.5557 7.90468 15.9288 8.27778 16.389 8.27778Z"
        fill="#FFCC4D"
      />
      <path
        d="M4.16634 14.3889C4.62658 14.3889 4.99967 14.0158 4.99967 13.5556C4.99967 13.0953 4.62658 12.7222 4.16634 12.7222C3.7061 12.7222 3.33301 13.0953 3.33301 13.5556C3.33301 14.0158 3.7061 14.3889 4.16634 14.3889Z"
        fill="#FFCC4D"
      />
    </svg>
  )
}
