import { useCallback, useMemo } from "react"

import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"
import styled from "styled-components"

import {
  useGetContestByIdQuery,
  useRefetchContestByIdMutation,
} from "@/store/api/contest"
import { createQuerySelector } from "@/store/api/helpers"

import Hourglass from "~/assets/icons/Hourglass"

import Line from "@/components/Line"
import Loader from "@/components/Loader"
import { SidebarHolder, SidebarItem } from "@/components/Sidebar"
import ContestSidebarTimer from "@/features/contest/ContestSidebarTimer"
import RandomContestProblem from "@/features/contest/RandomContestProblem"
import useContestStatus, { ContestStatus } from "@/shared/time/useContestStatus"
import { text16Medium } from "@/utils/fonts"

interface ContestSidebarProps {
  contestId: string
}

const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
const fallback = "ø"

const timerExcludedStatuses: ContestStatus[] = [
  ContestStatus.Ended,
  ContestStatus.Unknown,
]

export default function ContestSidebar({ contestId }: ContestSidebarProps) {
  const { t } = useTranslation()
  const [refetch] = useRefetchContestByIdMutation()

  const { problemId } = useParams<"problemId">()

  const {
    count = 0,
    name,
    problems = [],
    isFetching,
    isErrored,
    timings,
  } = useGetContestByIdQuery(
    { id: contestId },
    { selectFromResult: selectFromContest },
  )

  const status = useContestStatus(timings)
  const hasContestTimer = !timerExcludedStatuses.includes(status)

  const onExpire = useCallback(() => refetch(contestId), [contestId, refetch])

  const actualNames = useMemo(
    () =>
      problems.map(({ name, id }, i) => (
        <SidebarItem
          as={Link}
          to={`/contest/${contestId}/${id}`}
          key={id}
          data-selected={problemId === String(id)}
        >
          {letters[i] ?? fallback}. {name}
        </SidebarItem>
      )),
    [problems, problemId, contestId],
  )

  const randomNames = useMemo(
    () =>
      letters
        .slice(0, count + 5)
        .split("")
        .map(letter => (
          <RandomContestProblem
            key={letter}
            contestId={Number(contestId)}
            letter={letter}
          />
        )),
    [count, contestId],
  )

  if (isErrored) return <SidebarHolder>{fallback}</SidebarHolder>

  return (
    <Wrapper>
      <Loader size="small" loading={isFetching}>
        <ContestTitle>{name}</ContestTitle>

        {hasContestTimer && (
          <TimerWrapper>
            <Hourglass />

            <ContestSidebarTimer timings={timings} onExpire={onExpire} />
          </TimerWrapper>
        )}

        <SidebarItem
          as={Link}
          to={`/contest/${contestId}`}
          data-selected={!problemId}
        >
          {t`pages.contest.sidebar.description`}
        </SidebarItem>

        <Line />

        {problems.length > 0 ? actualNames : randomNames}
      </Loader>
    </Wrapper>
  )
}

const selectFromContest = createQuerySelector(
  useGetContestByIdQuery,
  ({ data, isFetching, isError }) => ({
    name: data?.name,
    problems: data?.problems,
    count: data?.task_count,
    isRegistered: data?.registered,
    timings: data?.timings,
    isFetching: isFetching,
    isErrored: isError,
  }),
)

const Wrapper = styled(SidebarHolder)`
  ${SidebarItem} {
    text-decoration: none;
  }

  ${SidebarItem}:not([data-selected="true"]) {
    opacity: 0.5;
  }

  ${SidebarItem}[data-selected="true"] {
    color: var(--color-left-bar-selection);
  }
`

const ContestTitle = styled.span`
  ${text16Medium};
`

const TimerWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: start;
  justify-content: start;

  gap: 8px;

  svg {
    padding: 2px 5px;
    width: 10px;
    height: 16px;

    fill: var(--color-text);

    opacity: 0.65;
  }
`
