import styled, { css } from "styled-components"

import { ThemeT } from "@/store/slices/common/types"

const animated = css`
  > path,
  > rect,
  > circle {
    transition: fill var(--transition-duration) var(--transition-function),
      stroke var(--transition-duration) var(--transition-function);
  }
`

export const UnknownSVG = styled.svg<{ $theme: ThemeT }>`
  ${animated};

  > rect {
    fill: ${({ $theme }) => ($theme === "dark" ? "#333333" : "#C4C4C4")};
  }

  > path {
    fill: ${({ $theme }) => ($theme === "dark" ? "#5F5F5F" : "#999999")};
  }
`

export const SilverSVG = styled.svg<{ $theme: ThemeT }>`
  ${animated};

  > rect {
    fill: ${({ $theme }) => ($theme === "dark" ? "#15252F" : "#E2ECF2")};
  }

  > path {
    fill: ${({ $theme }) => ($theme === "dark" ? "#A8B5BC" : "#627077")};
  }
`

export const GoldSVG = styled.svg<{ $theme: ThemeT }>`
  ${animated};

  > rect {
    fill: ${({ $theme }) => ($theme === "dark" ? "#462C09" : "#F9E8D0")};
  }

  > path {
    fill: ${({ $theme }) => ($theme === "dark" ? "#FFB84D" : "#FFAC33")};
  }
`

const PlatinumBorder = {
  light: [
    css`
      --grad-start: #ffac33;
      --grad-stop: #ffbb58;
    `,
    css`
      --grad-start: #ff7a00;
      --grad-stop: #ffa755;
    `,
    css`
      --grad-start: #e72727;
      --grad-stop: #ff8686;
    `,
  ],

  dark: [
    css`
      --grad-start: #ffb84c;
      --grad-stop: #ffc875;
    `,
    css`
      --grad-start: #ff6421;
      --grad-stop: #ff7134;
    `,
    css`
      --grad-start: #ec3737;
      --grad-stop: #f05454;
    `,
  ],
}

const PlatinumCircleColor = {
  light: ["#FFD983", "#FFD983", "#FFCC4D"],
  dark: ["#462C09", "#462C09", "#FFD467"],
}

export const PlatinumSVG = styled.svg<{ $theme: ThemeT; $rank: 0 | 1 | 2 }>`
  ${animated};

  ${({ $theme, $rank }) => PlatinumBorder[$theme][$rank]};

  transition: fill var(--transition-duration) var(--transition-function);

  rect:first-child {
    fill: ${({ $theme }) => ($theme === "dark" ? "#462C09" : "#F9E8D0")};
  }

  path,
  circle {
    fill: ${({ $theme }) => ($theme === "dark" ? "#FFB84D" : "#FFAC33")};
  }

  .inner-circle {
    fill: ${({ $theme, $rank }) => PlatinumCircleColor[$theme][$rank]};
  }
`

export const TopOneSVG = styled.svg<{ $theme: ThemeT }>`
  > rect:first-of-type {
    fill: #ffbb57;
  }

  > rect:last-of-type {
    stroke: #ffac33;
    fill: none;
  }
`
