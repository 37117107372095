export default function ClockIcon() {
  return (
    <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 0a1 1 0 011 1v.07A7.963 7.963 0 018 0c1.458 0 2.824.39 4.001 1.07L12 1a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 01-1 1h-.07A8 8 0 111.07 4H1a1 1 0 01-1-1V1a1 1 0 011-1h2zm5 2a6 6 0 100 12A6 6 0 008 2zm0 2a1 1 0 01.993.883L9 5v2.586l1.707 1.707a1 1 0 01-1.32 1.497l-.094-.083-2-2a1 1 0 01-.284-.576L7 8V5a1 1 0 011-1z"
        fill="#222"
        fillRule="evenodd"
      />
    </svg>
  )
}
