import { useCallback, useMemo } from "react"

import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import { ITabInfo } from "@/components/groups/types"
import MainHeader from "@/features/main/MainHeader"
import useHeaderTabGroup from "@/shared/tabs/useHeaderTabGroup"
import { useAppSelector } from "@/store"
import mediaQueryFor from "@/utils/mediaQuery"
import { ILinkDetails } from "@/widgets/header/types"
import useActiveLink from "@/widgets/header/useActiveLink"

interface IPageHeaderProps {
  links: ILinkDetails[]
  header: string
}

export default function PageHeader({ links, header }: IPageHeaderProps) {
  const navigate = useNavigate()

  const { t } = useTranslation()

  const isLoggedIn = useAppSelector(({ user }) => user.token !== null)

  const TabGroup = useHeaderTabGroup()

  const handleTabClick = useCallback(
    (tab: number) => {
      navigate(links[tab].href)
    },
    [links, navigate]
  )

  const activeLink = useActiveLink(links)

  const tabs: ITabInfo[] = useMemo(
    () => links.map(({ name }, index) => ({ name, tab: index })),
    [links]
  )

  return (
    <Wrapper>
      <MainHeader>{t(header)}</MainHeader>
      {isLoggedIn && (
        <TabGroup
          tabs={tabs}
          activeTab={activeLink}
          setActiveTab={handleTabClick}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 32px;

  ${mediaQueryFor.mobile} {
    flex-direction: column;
    gap: 8px;

    margin-bottom: 16px;
  }
`
