interface IPlusMarkerProps {
  width?: number
  height?: number
}

export default function PlusMarker({
  width = 20,
  height = 20,
}: IPlusMarkerProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99999 3.33301C10.4602 3.33301 10.8333 3.7061 10.8333 4.16634V9.16634H15.8333C16.2936 9.16634 16.6667 9.53944 16.6667 9.99967C16.6667 10.4599 16.2936 10.833 15.8333 10.833H10.8333V15.833C10.8333 16.2932 10.4602 16.6663 9.99999 16.6663C9.53976 16.6663 9.16666 16.2932 9.16666 15.833V10.833H4.16666C3.70642 10.833 3.33333 10.4599 3.33333 9.99967C3.33333 9.53944 3.70642 9.16634 4.16666 9.16634H9.16666V4.16634C9.16666 3.7061 9.53976 3.33301 9.99999 3.33301Z"
      />
    </svg>
  )
}
