export default function AlarmIcon({ width = 16, height = 16 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.52876 4.02861L3.52876 2.02861C3.78911 1.76826 4.21122 1.76826 4.47157 2.02861C4.73192 2.28896 4.73192 2.71107 4.47157 2.97141L2.47157 4.97141C2.21122 5.23176 1.78911 5.23176 1.52876 4.97141C1.26841 4.71106 1.26841 4.28895 1.52876 4.02861ZM12.4716 2.02861L14.4716 4.02861C14.7319 4.28895 14.7319 4.71106 14.4716 4.97141C14.2112 5.23176 13.7891 5.23176 13.5288 4.97141L11.5288 2.97141C11.2684 2.71107 11.2684 2.28896 11.5288 2.02861C11.7891 1.76826 12.2112 1.76826 12.4716 2.02861ZM8.00016 15.1667C11.3139 15.1667 14.0002 12.4804 14.0002 9.16668C14.0002 5.85297 11.3139 3.16668 8.00016 3.16668C4.68645 3.16668 2.00016 5.85297 2.00016 9.16668C2.00016 12.4804 4.68645 15.1667 8.00016 15.1667ZM8.00016 13.8333C5.42283 13.8333 3.3335 11.744 3.3335 9.16668C3.3335 6.58935 5.42283 4.50001 8.00016 4.50001C10.5775 4.50001 12.6668 6.58935 12.6668 9.16668C12.6668 11.744 10.5775 13.8333 8.00016 13.8333ZM8.00016 5.83334C8.36835 5.83334 8.66683 6.13182 8.66683 6.50001V8.22387L9.8049 9.36194C10.0653 9.62229 10.0653 10.0444 9.8049 10.3047C9.54455 10.5651 9.12244 10.5651 8.86209 10.3047L7.52876 8.97141C7.40373 8.84639 7.3335 8.67682 7.3335 8.50001V6.50001C7.3335 6.13182 7.63197 5.83334 8.00016 5.83334Z"
        fill="#909090"
      />
    </svg>
  )
}
