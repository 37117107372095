export default function ShareIcon({ width = 20, height = 20 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0003 1.66675C16.8413 1.66675 18.3337 3.15913 18.3337 5.00008C18.3337 6.84103 16.8413 8.33342 15.0003 8.33342C14.0255 8.33342 13.1484 7.91495 12.5389 7.24785L8.33366 9.65035V10.3498L12.5389 12.7523C13.1484 12.0852 14.0255 11.6667 15.0003 11.6667C16.8413 11.6667 18.3337 13.1591 18.3337 15.0001C18.3337 16.841 16.8413 18.3334 15.0003 18.3334C13.1594 18.3334 11.667 16.841 11.667 15.0001C11.667 14.7336 11.6983 14.4744 11.7573 14.2261L7.72461 11.9213C7.12106 12.7756 6.12583 13.3334 5.00033 13.3334C3.15938 13.3334 1.66699 11.841 1.66699 10.0001C1.66699 8.15913 3.15938 6.66675 5.00033 6.66675C6.12583 6.66675 7.12106 7.22457 7.72461 8.07882L11.7573 5.77411C11.6983 5.52572 11.667 5.26655 11.667 5.00008C11.667 3.15913 13.1594 1.66675 15.0003 1.66675ZM15.0003 13.3334C14.0799 13.3334 13.3337 14.0796 13.3337 15.0001C13.3337 15.9206 14.0799 16.6667 15.0003 16.6667C15.9208 16.6667 16.667 15.9206 16.667 15.0001C16.667 14.0796 15.9208 13.3334 15.0003 13.3334ZM5.00033 8.33341C4.07985 8.33341 3.33366 9.07961 3.33366 10.0001C3.33366 10.9206 4.07985 11.6667 5.00033 11.6667C5.9208 11.6667 6.66699 10.9206 6.66699 10.0001C6.66699 9.07961 5.9208 8.33341 5.00033 8.33341ZM15.0003 3.33341C14.0799 3.33341 13.3337 4.07961 13.3337 5.00008C13.3337 5.92056 14.0799 6.66675 15.0003 6.66675C15.9208 6.66675 16.667 5.92056 16.667 5.00008C16.667 4.07961 15.9208 3.33341 15.0003 3.33341Z"
      />
    </svg>
  )
}
