import { MouseEventHandler } from "react"

interface ILanguageSwitcherProps {
  width?: number
  height?: number
  onClick?: MouseEventHandler<SVGElement>
}

export default function LanguageSwitcher({
  width = 24,
  height = 24,
  onClick = () => {},
}: ILanguageSwitcherProps) {
  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.666748"
        y="2"
        width="22.6667"
        height="18"
        rx="2.15282"
        data-kind="background"
      />
      <path
        d="M5.04675 6.9H6.86675L10.1167 16H8.45275L7.69875 13.725H4.12375L3.35675 16H1.79675L5.04675 6.9ZM7.23075 12.295L5.94375 8.395H5.91775L4.60475 12.295H7.23075Z"
        data-kind="foreground"
      />
      <path
        d="M18.6557 8.26252C17.4533 12.0316 14.9676 14.1704 10.9673 15.2225C11.2448 15.5578 11.6842 16.2284 11.846 16.5752C15.985 15.2803 18.6788 12.8524 20.1356 8.6903L18.6557 8.26252ZM14.193 8.35502L12.8056 8.77123C14.2508 12.7715 16.6325 15.4191 20.968 16.5521C21.1877 16.1474 21.6386 15.4884 21.9739 15.1647C17.858 14.2398 15.3607 11.7772 14.193 8.35502ZM11.1755 7.51103V8.87528H21.7658V7.51103H11.1755ZM15.7191 5.68432V8.34345H17.1527V5.68432H15.7191Z"
        data-kind="foreground"
      />
      <path
        d="M23.3333 22.1556V18H10L22.2399 22.8959C22.7636 23.1054 23.3333 22.7197 23.3333 22.1556Z"
        data-kind="background"
      />
    </svg>
  )
}
