import { useTheme } from "@/store/hooks"

import { PlatinumSVG } from "./styled"

export default function RankPlatinumThree() {
  const theme = useTheme()

  return (
    <PlatinumSVG
      width="158"
      height="48"
      viewBox="0 0 158 48"
      xmlns="http://www.w3.org/2000/svg"
      $theme={theme}
      $rank={2}
    >
      <g clip-path="url(#outer)">
        <rect width="158" height="48" rx="8" />

        <g clip-path="url(#inner)">
          <path d="M61.6875 14.5278H69.5208C70.9622 14.5278 71.9714 14.392 71.9714 15.8333V13.2222C71.9714 11.7809 70.9622 10.6111 69.5208 10.6111H59.0764C56.4653 10.6111 54.3986 13.3854 55.1597 17.1389C55.1597 17.1389 57.498 29.3784 57.6625 30.1396C58.0972 32.1528 60.4355 34.1111 62.9382 34.1111H72.0223C73.4649 34.1111 71.9714 31.6358 71.9714 30.1944V27.5833C71.9714 29.0246 70.9622 30.1944 69.5208 30.1944H64.2986C62.8573 30.1944 61.9055 28.834 61.6875 27.5833C61.4695 26.3326 59.6195 18.0097 59.6195 18.0097C59.1848 15.5069 60.2449 14.5278 61.6875 14.5278ZM95.3865 14.5278H87.5532C86.1118 14.5278 83.7214 14.392 83.7214 15.8333V13.2222C83.7214 11.7809 86.1118 10.6111 87.5532 10.6111H97.9976C100.609 10.6111 102.677 13.3854 101.914 17.1389C101.914 17.1389 99.7823 29.4359 99.6021 30.1944C99.14 32.1528 96.9362 34.1111 94.3799 34.1111H85.0504C83.6091 34.1111 83.7214 31.6358 83.7214 30.1944V27.5833C83.7214 29.0246 86.1118 30.1944 87.5532 30.1944H92.7754C94.2167 30.1944 95.1698 28.834 95.3865 27.5833C95.6032 26.3326 97.4545 18.0097 97.4545 18.0097C97.888 15.5069 96.8291 14.5278 95.3865 14.5278ZM82.1965 36.7222C82.1965 27.6381 78.6597 36.7222 78.6597 36.7222C78.6597 36.7222 74.7431 27.6381 74.7431 36.7222C74.7431 45.8063 70.4465 49.7778 70.4465 49.7778H86.4944C86.4931 49.7778 82.1965 45.8063 82.1965 36.7222Z" />
          <path
            d="M93.023 16.5867C93.023 25.4318 84.1844 40.3373 78.6619 40.3373C73.1381 40.3373 64.3008 25.4318 64.3008 16.5867C64.3008 9.80429 65.6063 8.33685 68.2174 8.33685C70.0113 8.33685 76.0834 8.3016 78.6619 8.3016L87.8008 8.30029C91.6091 8.29899 93.023 9.19329 93.023 16.5867Z"
            className="inner-circle"
          />
        </g>

        <path d="M21.8232 30.3922C21.6575 30.3922 21.4919 30.3409 21.3496 30.2394L17.9992 27.8374L14.6499 30.2394C14.3641 30.4436 13.9815 30.4436 13.698 30.2359C13.4145 30.0306 13.2956 29.6655 13.4005 29.333L14.6499 25.3013L11.3311 22.9623C11.0487 22.7547 10.9321 22.3884 11.0394 22.0548C11.1479 21.7223 11.457 21.496 11.8082 21.4936L15.9192 21.4878L17.2281 17.5565C17.3389 17.224 17.6492 17 18.0003 17C18.3503 17 18.6618 17.224 18.7726 17.5565L20.0593 21.4878L24.1913 21.4936C24.5425 21.496 24.8528 21.7223 24.9601 22.0548C25.0686 22.3884 24.9508 22.7535 24.6685 22.9623L21.3484 25.3013L22.5978 29.333C22.7051 29.6666 22.5838 30.0306 22.3015 30.2359C22.158 30.3409 21.99 30.3922 21.8232 30.3922Z" />
        <path d="M40.8232 30.3922C40.6575 30.3922 40.4919 30.3409 40.3496 30.2394L36.9992 27.8374L33.6499 30.2394C33.3641 30.4436 32.9815 30.4436 32.698 30.2359C32.4145 30.0306 32.2956 29.6655 32.4005 29.333L33.6499 25.3013L30.3311 22.9623C30.0487 22.7547 29.9321 22.3884 30.0394 22.0548C30.1479 21.7223 30.457 21.496 30.8082 21.4936L34.9192 21.4878L36.2281 17.5565C36.3389 17.224 36.6492 17 37.0003 17C37.3503 17 37.6618 17.224 37.7726 17.5565L39.0593 21.4878L43.1913 21.4936C43.5425 21.496 43.8528 21.7223 43.9601 22.0548C44.0686 22.3884 43.9508 22.7535 43.6685 22.9623L40.3484 25.3013L41.5978 29.333C41.7051 29.6666 41.5838 30.0306 41.3015 30.2359C41.158 30.3409 40.99 30.3922 40.8232 30.3922Z" />
        <path d="M122.823 30.3922C122.658 30.3922 122.492 30.3409 122.35 30.2394L118.999 27.8374L115.65 30.2394C115.364 30.4436 114.982 30.4436 114.698 30.2359C114.415 30.0306 114.296 29.6655 114.401 29.333L115.65 25.3013L112.331 22.9623C112.049 22.7547 111.932 22.3884 112.039 22.0548C112.148 21.7223 112.457 21.496 112.808 21.4936L116.919 21.4878L118.228 17.5565C118.339 17.224 118.649 17 119 17C119.35 17 119.662 17.224 119.773 17.5565L121.059 21.4878L125.191 21.4936C125.542 21.496 125.853 21.7223 125.96 22.0548C126.069 22.3884 125.951 22.7535 125.668 22.9623L122.348 25.3013L123.598 29.333C123.705 29.6666 123.584 30.0306 123.301 30.2359C123.158 30.3409 122.99 30.3922 122.823 30.3922Z" />
        <path d="M141.823 30.3922C141.658 30.3922 141.492 30.3409 141.35 30.2394L137.999 27.8374L134.65 30.2394C134.364 30.4436 133.982 30.4436 133.698 30.2359C133.415 30.0306 133.296 29.6655 133.401 29.333L134.65 25.3013L131.331 22.9623C131.049 22.7547 130.932 22.3884 131.039 22.0548C131.148 21.7223 131.457 21.496 131.808 21.4936L135.919 21.4878L137.228 17.5565C137.339 17.224 137.649 17 138 17C138.35 17 138.662 17.224 138.773 17.5565L140.059 21.4878L144.191 21.4936C144.542 21.496 144.853 21.7223 144.96 22.0548C145.069 22.3884 144.951 22.7535 144.668 22.9623L141.348 25.3013L142.598 29.333C142.705 29.6666 142.584 30.0306 142.301 30.2359C142.158 30.3409 141.99 30.3922 141.823 30.3922Z" />
      </g>

      <rect
        x="1"
        y="1"
        width="156"
        height="46"
        rx="7"
        stroke="url(#grad)"
        stroke-width="2"
        fill="none"
      />

      <defs>
        <linearGradient
          id="grad"
          x1="158"
          y1="48"
          x2="-3.78942e-07"
          y2="1.24735e-06"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--grad-start)" />
          <stop offset="1" stopColor="var(--grad-stop)" />
        </linearGradient>

        <clipPath id="outer">
          <rect width="158" height="48" rx="8" fill="white" />
        </clipPath>

        <clipPath id="inner">
          <rect
            width="47"
            height="47"
            transform="translate(55 8)"
            fill="white"
          />
        </clipPath>
      </defs>
    </PlatinumSVG>
  )
}
