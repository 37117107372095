export default function Hourglass() {
  return (
    <svg fill="none" viewBox="0 0 10 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 0a3 3 0 012.995 2.824L10 3v2a1 1 0 01-.31.724L9.6 5.8 6.667 8 9.6 10.2a1 1 0 01.4.8v2a3 3 0 01-3 3H3a3 3 0 01-3-3v-2a1 1 0 01.4-.8L3.333 8 .4 5.8a1 1 0 01-.393-.683L0 5V3A3 3 0 012.824.005L3 0h4zM5 9.25L2 11.5V13a1 1 0 001 1h4l.117-.007A1 1 0 008 13v-1.5L5 9.25zM3 2h4l.117.007.112.02A1 1 0 018 3v1H2V3l.007-.117A1 1 0 013 2z"
        fillRule="evenodd"
      />
    </svg>
  )
}
