export default function OpenInNewIcon() {
  return (
    <svg fill="none" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.917 1.167h1.166a.583.583 0 01.068 1.162l-.068.004H2.917c-.3 0-.546.225-.58.515l-.004.069v8.166c0 .3.225.546.516.58l.068.003h8.166c.3 0 .546-.225.58-.515l.004-.068V9.916a.583.583 0 011.162-.068l.004.068v1.167a1.75 1.75 0 01-1.647 1.747l-.103.003H2.917a1.75 1.75 0 01-1.747-1.647l-.003-.103V2.917a1.75 1.75 0 011.647-1.748l.103-.002h1.166-1.166zm9.333 0l.047.001.07.01.065.018.065.025.057.03.056.04.052.046.057.066.042.064.018.036.02.048.013.044.017.086.004.069v3.5a.583.583 0 01-1.166 0V3.158L7.996 6.829a.583.583 0 01-.77.049l-.055-.049a.583.583 0 01-.049-.77l.049-.055 3.67-3.67H8.75a.583.583 0 110-1.167h3.5z"
        fillRule="evenodd"
      />
    </svg>
  )
}
