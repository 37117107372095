export default function LockIcon() {
  return (
    <svg fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 1.667a4.167 4.167 0 014.163 3.986l.004.18v2.5a2.5 2.5 0 012.5 2.5v5a2.5 2.5 0 01-2.5 2.5H5.833a2.5 2.5 0 01-2.5-2.5v-5a2.5 2.5 0 012.5-2.5v-2.5A4.167 4.167 0 0110 1.667zM14.167 10H5.833a.833.833 0 00-.833.833v5c0 .46.373.834.833.834h8.334c.46 0 .833-.373.833-.834v-5a.833.833 0 00-.833-.833zm-4.02-6.662L10 3.333a2.5 2.5 0 00-2.496 2.353l-.004.147v2.5h5v-2.5a2.5 2.5 0 00-2.353-2.495L10 3.333l.147.005z"
        fillRule="evenodd"
      />
    </svg>
  )
}
