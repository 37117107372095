export default function ArchiveIcon({ width = 24, height = 24 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 2C21.1569 2 22.5 3.34315 22.5 5V19C22.5 20.6569 21.1569 22 19.5 22H5.5C3.84315 22 2.5 20.6569 2.5 19V5C2.5 3.34315 3.84315 2 5.5 2H19.5ZM20.5 8H4.5V19C4.5 19.5523 4.94772 20 5.5 20H19.5C20.0523 20 20.5 19.5523 20.5 19V8ZM12.6168 17.9933L12.5001 18L12.416 17.996L12.2994 17.9798L12.188 17.9503L12.0769 17.9063L11.9794 17.854L11.9049 17.8037L11.793 17.7071L8.7929 14.7071C8.40237 14.3166 8.40237 13.6834 8.7929 13.2929C9.15339 12.9324 9.72064 12.9047 10.1129 13.2097L10.2072 13.2929L11.5 14.585L11.5001 11C11.5001 10.4872 11.8861 10.0645 12.3835 10.0067L12.5001 10C13.0524 10 13.5001 10.4477 13.5001 11L13.5 14.585L14.7931 13.2929C15.1535 12.9324 15.7208 12.9047 16.1131 13.2097L16.2073 13.2929C16.5678 13.6534 16.5955 14.2206 16.2905 14.6129L16.2073 14.7071L13.2072 17.7071L13.1217 17.7834L13.0519 17.8341L12.954 17.8913L12.8401 17.9407L12.7661 17.9642L12.6168 17.9933ZM19.5 4H5.5C4.94772 4 4.5 4.44772 4.5 5V6H20.5V5C20.5 4.44772 20.0523 4 19.5 4Z"
        fill="#888888"
      />
    </svg>
  )
}
