import { useTheme } from "@/store/hooks"

import { UnknownSVG } from "./styled"

export default function RankUnknown() {
  const theme = useTheme()

  return (
    <UnknownSVG
      width="158"
      height="48"
      viewBox="0 0 158 48"
      xmlns="http://www.w3.org/2000/svg"
      $theme={theme}
    >
      <rect width="158" height="48" rx="8" />
      <path d="M77.33 25.148C77.33 24.536 77.402 24.032 77.546 23.636C77.69 23.228 77.864 22.886 78.068 22.61C78.284 22.322 78.512 22.082 78.752 21.89C79.004 21.698 79.232 21.512 79.436 21.332C79.652 21.14 79.832 20.93 79.976 20.702C80.12 20.462 80.192 20.168 80.192 19.82C80.192 19.34 80.042 18.962 79.742 18.686C79.454 18.398 79.04 18.254 78.5 18.254C77.936 18.254 77.492 18.41 77.168 18.722C76.856 19.034 76.676 19.46 76.628 20H74.36C74.384 19.448 74.492 18.944 74.684 18.488C74.888 18.032 75.164 17.636 75.512 17.3C75.872 16.964 76.292 16.706 76.772 16.526C77.264 16.334 77.81 16.238 78.41 16.238C79.07 16.238 79.652 16.322 80.156 16.49C80.66 16.658 81.08 16.892 81.416 17.192C81.764 17.492 82.022 17.852 82.19 18.272C82.37 18.68 82.46 19.136 82.46 19.64C82.46 20.156 82.388 20.588 82.244 20.936C82.1 21.284 81.914 21.59 81.686 21.854C81.47 22.106 81.23 22.334 80.966 22.538C80.714 22.742 80.474 22.964 80.246 23.204C80.03 23.444 79.85 23.714 79.706 24.014C79.562 24.314 79.49 24.692 79.49 25.148H77.33ZM78.41 29.162C77.99 29.162 77.642 29.024 77.366 28.748C77.102 28.46 76.97 28.118 76.97 27.722C76.97 27.326 77.102 26.99 77.366 26.714C77.642 26.426 77.99 26.282 78.41 26.282C78.83 26.282 79.172 26.426 79.436 26.714C79.712 26.99 79.85 27.326 79.85 27.722C79.85 28.118 79.712 28.46 79.436 28.748C79.172 29.024 78.83 29.162 78.41 29.162Z" />
    </UnknownSVG>
  )
}
