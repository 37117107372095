interface IDraggableIconProps {
  width?: number
  height?: number
}

export default function DraggableIcon({
  width = 5,
  height = 14,
}: IDraggableIconProps) {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 5 14"
      fill="none"
    >
      <circle cx="1" cy="1" r="1" />
      <circle cx="4" cy="4" r="1" />
      <circle cx="1" cy="7" r="1" />
      <circle cx="4" cy="10" r="1" />
      <circle cx="1" cy="13" r="1" />
    </svg>
  )
}
