export default function Puzzle({ width = 20, height = 20 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4961 2.56226C14.2195 2.83893 14.1467 3.02837 14.0917 3.13393C13.725 3.83726 13.2834 4.06504 12.5473 3.32893C12.475 3.2567 10.2767 0.958371 9.91337 0.541704C9.67615 0.269482 9.31281 0.186149 9.03337 0.396149C8.82226 0.555038 6.7917 2.21448 6.23615 2.90004C5.80615 3.43059 5.7917 4.08559 6.19226 4.4867C6.37337 4.66782 6.59615 4.73615 6.8317 4.80782C7.13837 4.9017 7.81115 4.84559 8.20393 5.23837C8.83337 5.86782 8.50393 6.86282 7.69448 7.69448C6.86281 8.50393 5.86781 8.83337 5.23837 8.20393C4.84504 7.81059 4.9017 7.13837 4.80781 6.8317C4.73615 6.59615 4.66781 6.37393 4.4867 6.19226C4.08615 5.7917 3.43115 5.8067 2.90004 6.23615C2.21393 6.7917 0.555036 8.82226 0.396148 9.03337C0.185592 9.31281 0.269481 9.67559 0.541703 9.91337C0.95837 10.2767 3.2567 12.475 3.32893 12.5473C4.06448 13.2828 3.83726 13.725 3.13393 14.0917C3.02837 14.1467 2.83948 14.2189 2.56226 14.4961C1.75004 15.3084 1.75004 16.6256 2.56226 17.4378C3.37448 18.25 4.6917 18.25 5.50393 17.4378C5.78059 17.1611 5.85337 16.9717 5.90837 16.8661C6.27504 16.1628 6.7167 15.935 7.45281 16.6711C7.52504 16.7434 9.72337 19.0417 10.0867 19.4584C10.3239 19.7306 10.6873 19.8139 10.9667 19.6039C11.1778 19.445 13.2084 17.7856 13.7639 17.1C14.1939 16.5695 14.2084 15.9145 13.8078 15.5134C13.6267 15.3323 13.4039 15.2639 13.1684 15.1923C12.8617 15.0984 12.1889 15.1545 11.7961 14.7617C11.1667 14.1323 11.4961 13.1373 12.3056 12.3056C13.1373 11.4961 14.1323 11.1667 14.7617 11.7961C15.155 12.1895 15.0984 12.8617 15.1923 13.1684C15.2639 13.4039 15.3323 13.6261 15.5134 13.8078C15.9139 14.2084 16.5689 14.1934 17.1 13.7639C17.7861 13.2084 19.445 11.1778 19.6039 10.9667C19.8145 10.6873 19.7306 10.3245 19.4584 10.0867C19.0417 9.72337 16.7434 7.52504 16.6711 7.45282C15.9356 6.71726 16.1628 6.27504 16.8661 5.90837C16.9717 5.85337 17.1606 5.78115 17.4378 5.50393C18.25 4.6917 18.25 3.37448 17.4378 2.56226C16.6256 1.74948 15.3084 1.74948 14.4961 2.56226Z"
        fill="#78B159"
      />
    </svg>
  )
}
