import styled from "styled-components"

import { ThemeT } from "@/store/slices/common/types"

import { useTheme } from "@/store/hooks"

export default function AwardSilverMedal() {
  const theme = useTheme()

  return (
    <SilverMedalSVG
      width="40"
      height="40"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      $theme={theme}
    >
      <path d="M20.5 8.88889L12.7222 0H0.5L16.0556 18.8889L28.8567 13.6111L20.5 8.88889Z" />
      <path d="M28.2778 0L20.5 8.88889L26.4889 17.0133L27.8522 15.3589L40.5 0H28.2778Z" />
      <path d="M26.3432 17.8067C26.432 17.5656 26.4887 17.3089 26.4887 17.0367C26.4887 15.81 25.4932 14.8145 24.2665 14.8145H16.8587C15.6309 14.8145 14.6365 15.81 14.6365 17.0367C14.6365 17.3089 14.692 17.5656 14.782 17.8067C11.162 19.8356 8.71094 23.7033 8.71094 28.1478C8.71094 34.6933 14.0165 40 20.5632 40C27.1087 40 32.4154 34.6933 32.4154 28.1478C32.4143 23.7033 29.9643 19.8356 26.3432 17.8067Z" />
      <path d="M20.5647 37.0366C25.4739 37.0366 29.4536 33.0569 29.4536 28.1477C29.4536 23.2385 25.4739 19.2588 20.5647 19.2588C15.6555 19.2588 11.6758 23.2385 11.6758 28.1477C11.6758 33.0569 15.6555 37.0366 20.5647 37.0366Z" />
      <path d="M24.1407 34.0378C23.9829 34.0378 23.8251 33.9889 23.6896 33.8922L20.4985 31.6045L17.3085 33.8922C17.0362 34.0867 16.6718 34.0867 16.4018 33.8889C16.1318 33.6933 16.0185 33.3456 16.1185 33.0289L17.3085 29.1889L14.1473 26.9611C13.8785 26.7633 13.7673 26.4145 13.8696 26.0967C13.9729 25.78 14.2673 25.5645 14.6018 25.5622L18.5173 25.5567L19.764 21.8122C19.8696 21.4956 20.1651 21.2822 20.4996 21.2822C20.8329 21.2822 21.1296 21.4956 21.2351 21.8122L22.4607 25.5567L26.3962 25.5622C26.7307 25.5645 27.0262 25.78 27.1285 26.0967C27.2318 26.4145 27.1196 26.7622 26.8507 26.9611L23.6885 29.1889L24.8785 33.0289C24.9807 33.3467 24.8651 33.6933 24.5962 33.8889C24.4596 33.9889 24.2996 34.0378 24.1407 34.0378Z" />
    </SilverMedalSVG>
  )
}

const SilverMedalSVG = styled.svg<{ $theme: ThemeT }>`
  path:nth-child(1) {
    fill: ${({ $theme }) => ($theme === "dark" ? "#6CB7F0" : "#55ACEE")};
  }

  path:nth-child(2) {
    fill: ${({ $theme }) => ($theme === "dark" ? "#4E94C9" : "#3B88C3")};
  }

  path:nth-child(5) {
    fill: ${({ $theme }) => ($theme === "dark" ? "#A8B5BC" : "#627077")};
  }

  path {
    fill: ${({ $theme }) => ($theme === "dark" ? "#2C3942" : "#CCD6DD")};
  }
`
