interface IYandexProps {
  width?: number
  height?: number
  className?: string
}

export default function Yandex({
  width = 153,
  height = 35,
  className = "",
}: IYandexProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 153 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_10733_18443)">
        <path
          d="M17.0343 0.0639648C8.10871 0.0639648 0.927979 7.2447 0.927979 16.1703C0.927979 25.0959 8.10871 32.2766 17.0343 32.2766C25.9599 32.2766 33.1406 25.0959 33.1406 16.1703C33.1406 7.2447 25.9599 0.0639648 17.0343 0.0639648ZM22.5373 25.8341H19.1818V9.19088H17.7054C14.9539 9.19088 13.5446 10.6002 13.5446 12.6135C13.5446 14.8952 14.5512 15.9689 16.5645 17.3782L18.2423 18.5191L13.4104 25.767H9.78645L14.1486 19.3244C11.6655 17.5125 10.2562 15.7676 10.2562 12.8819C10.2562 9.19088 12.8064 6.70782 17.7054 6.70782H22.5373V25.8341Z"
          fill="black"
        />
        <path
          d="M53.7633 28.1763H58.6824L50.032 15.4053L58.1254 3.92285H53.9378L46.0859 15.0899V3.92285H41.8647V28.1763H46.0859V16.8012L53.7633 28.1763ZM66.1182 10.2714C61.548 10.2714 58.3737 13.5195 58.3737 19.4185C58.3737 25.2771 61.548 28.5252 66.1182 28.5252C70.6883 28.5252 73.8626 25.2771 73.8626 19.3782C73.8626 13.5195 70.6883 10.2714 66.1182 10.2714ZM66.1182 25.2436C63.8163 25.2436 62.662 23.4652 62.662 19.4185C62.662 15.3315 63.8163 13.5531 66.1182 13.5531C68.42 13.5531 69.5743 15.3315 69.5743 19.3782C69.5743 23.4652 68.42 25.2436 66.1182 25.2436ZM86.3584 10.6204V17.5662H80.8085V10.6204H76.6544V28.1763H80.8085V20.8479H86.3584V28.1763H90.5125V10.6204L86.3584 10.6204ZM106.881 13.9021V10.6204H92.9553V13.9021H97.8409V28.1763H101.995V13.9021H106.881ZM121.537 26.9884V23.6061C120.242 24.4785 118.081 25.2436 116.054 25.2436C113.021 25.2436 111.867 23.8141 111.692 20.8815H121.712V18.6803C121.712 12.5733 119.021 10.2714 114.867 10.2714C109.807 10.2714 107.404 14.1436 107.404 19.452C107.404 25.559 110.404 28.5252 115.705 28.5252C118.356 28.5252 120.316 27.8273 121.537 26.9884ZM114.799 13.5531C116.86 13.5531 117.484 15.2644 117.484 17.4589V17.8078H111.692C111.8 15.0161 112.813 13.5531 114.799 13.5531ZM132.181 28.5252C134.1 28.5252 135.456 28.1763 136.469 27.4448V24.0557C135.422 24.7872 134.167 25.2436 132.422 25.2436C129.456 25.2436 128.235 22.9417 128.235 19.3111C128.235 15.506 129.738 13.5531 132.456 13.5531C134.06 13.5531 135.63 14.1101 136.469 14.6335V11.1103C135.597 10.6204 134.06 10.2714 132.006 10.2714C126.698 10.2714 123.94 14.0765 123.94 19.4185C123.94 25.2771 126.631 28.5252 132.181 28.5252ZM152.139 13.9021V10.6204H138.214V13.9021H143.1V28.1763H147.254V13.9021H152.139Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_10733_18443">
          <rect
            width="151.909"
            height="34.897"
            fill="white"
            transform="translate(0.927979 0.0639648)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
