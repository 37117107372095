import { useTheme } from "@/store/hooks"

import { SilverSVG } from "./styled"

export default function RankSilverTwo() {
  const theme = useTheme()

  return (
    <SilverSVG
      width="158"
      height="48"
      viewBox="0 0 158 48"
      xmlns="http://www.w3.org/2000/svg"
      $theme={theme}
    >
      <rect width="158" height="48" rx="8" />
      <path d="M68.4617 33.1318C68.2251 33.1318 67.9884 33.0584 67.7851 32.9135L62.9988 29.4821L58.2142 32.9135C57.8059 33.2051 57.2593 33.2051 56.8543 32.9084C56.4494 32.6151 56.2794 32.0935 56.4294 31.6186L58.2142 25.859L53.4729 22.5176C53.0696 22.221 52.903 21.6977 53.0563 21.2211C53.2113 20.7461 53.6529 20.4228 54.1545 20.4195L60.0274 20.4111L61.8972 14.7949C62.0556 14.32 62.4989 14 63.0005 14C63.5004 14 63.9454 14.32 64.1037 14.7949L65.9419 20.4111L71.8448 20.4195C72.3464 20.4228 72.7897 20.7461 72.943 21.2211C73.098 21.6977 72.9297 22.2193 72.5264 22.5176L67.7834 25.859L69.5683 31.6186C69.7216 32.0952 69.5483 32.6151 69.145 32.9084C68.94 33.0584 68.7 33.1318 68.4617 33.1318Z" />
      <path d="M100.462 33.1318C100.225 33.1318 99.9884 33.0584 99.7851 32.9135L94.9988 29.4821L90.2142 32.9135C89.8059 33.2051 89.2593 33.2051 88.8543 32.9084C88.4494 32.6151 88.2794 32.0935 88.4294 31.6186L90.2142 25.859L85.4729 22.5176C85.0696 22.221 84.903 21.6977 85.0563 21.2211C85.2113 20.7461 85.6529 20.4228 86.1545 20.4195L92.0274 20.4111L93.8972 14.7949C94.0556 14.32 94.4989 14 95.0005 14C95.5004 14 95.9454 14.32 96.1037 14.7949L97.9419 20.4111L103.845 20.4195C104.346 20.4228 104.79 20.7461 104.943 21.2211C105.098 21.6977 104.93 22.2193 104.526 22.5176L99.7834 25.859L101.568 31.6186C101.722 32.0952 101.548 32.6151 101.145 32.9084C100.94 33.0584 100.7 33.1318 100.462 33.1318Z" />
    </SilverSVG>
  )
}
