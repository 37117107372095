interface IWaringIconProps {
  width?: number
  height?: number
}

export default function WarningIcon({
  width = 18,
  height = 18,
}: IWaringIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ic24-warning">
        <path
          id="Icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.88381 1.70912C10.2664 1.9004 10.5766 2.2106 10.7678 2.59316L16.2909 13.6392C16.7791 14.6157 16.3833 15.8031 15.4068 16.2913C15.1324 16.4285 14.8297 16.5 14.5228 16.5H3.47675C2.38502 16.5 1.5 15.615 1.5 14.5232C1.5 14.2164 1.57145 13.9137 1.70869 13.6392L7.23172 2.59316C7.71996 1.61668 8.90734 1.22089 9.88381 1.70912ZM8.42793 3.35366L3.06752 14.0745C3.02312 14.1633 3 14.2612 3 14.3605C3 14.7137 3.28632 15 3.63952 15H14.3603C14.4596 15 14.5575 14.9769 14.6463 14.9325C14.9623 14.7745 15.0903 14.3904 14.9323 14.0745L9.57193 3.35366C9.51005 3.22989 9.40969 3.12954 9.28593 3.06766C8.97002 2.9097 8.58588 3.03775 8.42793 3.35366ZM9 12C9.41421 12 9.75 12.3358 9.75 12.75C9.75 13.1642 9.41421 13.5 9 13.5C8.58579 13.5 8.25 13.1642 8.25 12.75C8.25 12.3358 8.58579 12 9 12ZM9 6C9.41421 6 9.75 6.33579 9.75 6.75V9.75C9.75 10.1642 9.41421 10.5 9 10.5C8.58579 10.5 8.25 10.1642 8.25 9.75V6.75C8.25 6.33579 8.58579 6 9 6Z"
          fill="#F88C29"
        />
      </g>
    </svg>
  )
}
