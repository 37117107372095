export default function UserIcon() {
  return (
    <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 10h6a5 5 0 015 5 1 1 0 01-1.993.117l-.012-.293a3 3 0 00-2.819-2.819L11 12H5a3 3 0 00-3 3 1 1 0 11-2 0 5 5 0 014.783-4.995L5 10h6-6zM8 0a5 5 0 110 10A5 5 0 018 0zm0 2a3 3 0 100 6 3 3 0 000-6z"
        fillRule="evenodd"
      />
    </svg>
  )
}
