export default function DisappointedEmoji() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8830_15428)">
        <path
          d="M48 24C48 37.2547 37.2547 48 24 48C10.7467 48 0 37.2547 0 24C0 10.7467 10.7467 0 24 0C37.2547 0 48 10.7467 48 24Z"
          fill="#FFCC4D"
        />
        <path
          d="M15.3333 26.6666C17.1743 26.6666 18.6667 24.5772 18.6667 21.9999C18.6667 19.4226 17.1743 17.3333 15.3333 17.3333C13.4924 17.3333 12 19.4226 12 21.9999C12 24.5772 13.4924 26.6666 15.3333 26.6666Z"
          fill="#664500"
        />
        <path
          d="M32.6666 26.6666C34.5075 26.6666 35.9999 24.5772 35.9999 21.9999C35.9999 19.4226 34.5075 17.3333 32.6666 17.3333C30.8256 17.3333 29.3333 19.4226 29.3333 21.9999C29.3333 24.5772 30.8256 26.6666 32.6666 26.6666Z"
          fill="#664500"
        />
        <path
          d="M31.3133 37.1719C31.2987 37.1133 29.7867 31.3333 24 31.3333C18.2133 31.3333 16.7013 37.1133 16.6867 37.1719C16.616 37.4559 16.7453 37.7466 16.996 37.8973C17.2467 38.0466 17.5733 38.0119 17.7907 37.8173C17.8027 37.8079 19.1413 36.6666 24 36.6666C28.7867 36.6666 30.156 37.7733 30.208 37.8173C30.3347 37.9386 30.5 37.9999 30.6667 37.9999C30.7787 37.9999 30.892 37.9719 30.9947 37.9146C31.256 37.7666 31.3867 37.4626 31.3133 37.1719Z"
          fill="#664500"
        />
        <path
          d="M13.3333 40.0001C13.3333 43.6827 10.3493 46.6667 6.66667 46.6667C2.984 46.6667 0 43.6827 0 40.0001C0 36.3174 5.33333 26.6667 6.66667 26.6667C8 26.6667 13.3333 36.3174 13.3333 40.0001Z"
          fill="#5DADEC"
        />
        <path
          d="M40.0001 17.3333C32.5947 17.3333 29.5974 11.5106 29.4734 11.2626C29.1441 10.604 29.4107 9.80262 30.0694 9.47328C30.7254 9.14395 31.5254 9.40928 31.8561 10.064C31.9561 10.2586 34.2841 14.6666 40.0001 14.6666C40.7374 14.6666 41.3334 15.264 41.3334 16C41.3334 16.7373 40.7374 17.3333 40.0001 17.3333ZM8.00008 17.3333C7.26408 17.3333 6.66675 16.736 6.66675 16C6.66675 15.264 7.26408 14.6666 8.00008 14.6666C14.7774 14.6666 15.9947 10.5066 16.0441 10.3293C16.2374 9.62528 16.9641 9.19862 17.6694 9.38395C18.3761 9.56928 18.8041 10.2813 18.6267 10.9906C18.5614 11.2493 16.9387 17.3333 8.00008 17.3333Z"
          fill="#664500"
        />
      </g>
      <defs>
        <clipPath id="clip0_8830_15428">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
