// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Gilroy_ExtraBold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/PT-Root-UI_Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/PT-Root-UI_Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/PT-Root-UI_Medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./fonts/PT-Root-UI_Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./fonts/JetBrainsMono-Regular.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: Gilroy;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n@font-face {\n  font-family: PT-Root-UI-Light;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n\n@font-face {\n  font-family: PT-Root-UI;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n\n@font-face {\n  font-family: PT-Root-UI-Medium;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n\n@font-face {\n  font-family: PT-Root-UI-Bold;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n  font-weight: 700;\n}\n\n@font-face {\n  font-family: JetBrains-Mono;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");\n}", "",{"version":3,"sources":["webpack://./src/assets/fonts.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,4CAAwC;AAC1C;;AAEA;EACE,6BAA6B;EAC7B,4CAAwC;AAC1C;;AAEA;EACE,uBAAuB;EACvB,4CAA0C;AAC5C;;AAEA;EACE,8BAA8B;EAC9B,4CAAyC;AAC3C;;AAEA;EACE,4BAA4B;EAC5B,4CAAuC;EACvC,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;EAC3B,4CAA6C;AAC/C","sourcesContent":["@font-face {\n  font-family: Gilroy;\n  src: url(./fonts/Gilroy_ExtraBold.woff2);\n}\n\n@font-face {\n  font-family: PT-Root-UI-Light;\n  src: url(./fonts/PT-Root-UI_Light.woff2);\n}\n\n@font-face {\n  font-family: PT-Root-UI;\n  src: url(./fonts/PT-Root-UI_Regular.woff2);\n}\n\n@font-face {\n  font-family: PT-Root-UI-Medium;\n  src: url(./fonts/PT-Root-UI_Medium.woff2);\n}\n\n@font-face {\n  font-family: PT-Root-UI-Bold;\n  src: url(./fonts/PT-Root-UI_Bold.woff2);\n  font-weight: 700;\n}\n\n@font-face {\n  font-family: JetBrains-Mono;\n  src: url(./fonts/JetBrainsMono-Regular.woff2);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
