export default function OkHandIcon({ width = 20, height = 20 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8978 11.6317L11.9417 7.82061L9.74895 2.92283C9.47006 2.20227 9.82839 1.39172 10.5489 1.11338C11.2689 0.832273 12.0801 1.19283 12.3589 1.91338L15.8051 10.6739L12.8978 11.6317Z"
        fill="#EF9645"
      />
      <path
        d="M17.5364 10.2494C17.2653 8.81554 16.432 6.62554 16.432 6.62554L13.217 0.798321C12.8692 0.108321 12.027 -0.168345 11.3375 0.178877C10.6475 0.526654 10.3698 1.36832 10.7181 2.05832L13.6792 7.36221C13.6864 7.37443 14.4642 10.4128 14.4642 10.4128C13.8653 9.85999 13.0159 9.10221 12.2336 8.40277C10.5125 6.86277 9.49142 6.28554 9.49142 6.28554C9.09698 6.03943 8.83642 6.00443 8.30698 6.25277L5.04476 7.67943C4.95753 7.72277 4.88365 7.78388 4.82531 7.86221L3.54031 9.57665C3.0442 10.1694 3.12253 11.0517 3.71531 11.5472C4.30753 12.0433 5.1892 11.9639 5.68642 11.3728C5.71309 11.3405 6.67531 9.95054 6.76198 9.9061C7.33809 9.60999 8.4092 9.10221 8.87753 9.11721C9.37587 9.13332 12.367 12.4267 12.367 12.4267C12.4814 12.5705 12.4948 12.77 12.4003 12.9278L10.7809 15.8322C10.737 15.905 10.6736 15.9633 10.5975 16.0005L9.11198 16.8339C9.02642 16.8755 8.92976 16.8889 8.83642 16.8711L5.90865 15.6089C5.76309 15.5461 5.64198 15.4311 5.57976 15.285C5.14253 14.2572 5.08087 13.2744 5.08031 13.2633C5.0442 12.4911 4.3892 11.895 3.61698 11.9311C2.84642 11.9622 2.24865 12.6217 2.28476 13.3939C2.29198 13.54 2.47976 16.5272 4.91087 18.5561C6.31531 19.7272 8.05198 20.1805 10.2714 19.8822C12.8336 19.5383 14.4209 19.1972 16.6636 14.5722C17.2842 13.2917 17.7209 11.2255 17.5364 10.2494Z"
        fill="#FFDC5D"
      />
    </svg>
  )
}
