import { useTheme } from "@/store/hooks"

import { TopOneSVG } from "./styled"

export default function RankTopOne() {
  const theme = useTheme()

  return (
    <TopOneSVG
      width="158"
      height="48"
      viewBox="0 0 158 48"
      xmlns="http://www.w3.org/2000/svg"
      $theme={theme}
    >
      <rect x="1" y="1" width="156" height="46" rx="7" />
      <path
        d="M81.0725 22.52C83.1125 22.52 84.5325 20.98 84.5325 19.12C84.5325 17.24 83.0725 15.72 81.0725 15.72C79.0725 15.72 77.6125 17.24 77.6125 19.12C77.6125 20.98 79.0725 22.52 81.0725 22.52ZM73.2125 16V23.6L67.8125 16H65.4125V30H68.6125V22.4L74.0125 30H76.4125V16H73.2125ZM81.0725 20.12C80.4925 20.12 80.0925 19.7 80.0925 19.12C80.0925 18.54 80.4925 18.12 81.0725 18.12C81.6525 18.12 82.0525 18.54 82.0525 19.12C82.0525 19.72 81.6725 20.12 81.0725 20.12ZM77.8925 26.32H84.3325V23.52H77.8925V26.32ZM89.5811 16L85.9211 17.32L86.7011 20.06L89.1811 19.38V30H92.3811V16H89.5811Z"
        fill="white"
      />
      <rect x="1" y="1" width="156" height="46" rx="7" stroke-width="2" />
    </TopOneSVG>
  )
}
