export default function TelegramSmallIcon({ width = 14, height = 14 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="#69a8e5"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="14" height="14" fill="none" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.14114 6.64537C4.51143 5.23178 6.75882 4.29986 7.88331 3.8496C11.094 2.56402 11.7611 2.3407 12.1959 2.33333C12.2916 2.33171 12.5054 2.35453 12.6439 2.46273C12.7609 2.55409 12.7931 2.67751 12.8085 2.76413C12.8239 2.85075 12.843 3.04808 12.8278 3.20227C12.6538 4.96213 11.901 9.23284 11.518 11.2039C11.3559 12.0379 11.0345 12.2165 10.7256 12.2438C10.0542 12.3033 9.5467 11.9178 8.89644 11.5075C7.87891 10.8654 7.5049 10.6924 6.51721 10.0659C5.37577 9.34173 5.99852 8.96271 6.64902 8.31228C6.81926 8.14206 9.79838 5.49041 9.85563 5.25543C9.86279 5.22605 9.76479 4.93222 9.69719 4.87438C9.62959 4.81654 9.52982 4.83632 9.45782 4.85205C9.35577 4.87435 7.73026 5.90866 4.58129 7.95497C4.11989 8.25998 3.70197 8.40859 3.32753 8.4008C2.91474 8.39221 2.1207 8.17611 1.53041 7.99139C0.806392 7.76483 0.539133 7.65813 0.589241 7.27335C0.615339 7.07293 0.799306 6.8636 1.14114 6.64537Z"
      />
    </svg>
  )
}
