import { useTheme } from "@/store/hooks"

import { GoldSVG } from "./styled"

export default function RankGoldThree() {
  const theme = useTheme()

  return (
    <GoldSVG
      width="158"
      height="48"
      viewBox="0 0 158 48"
      xmlns="http://www.w3.org/2000/svg"
      $theme={theme}
    >
      <rect width="158" height="48" rx="8" />
      <path d="M52.4617 33.1318C52.2251 33.1318 51.9884 33.0584 51.7851 32.9135L46.9988 29.4821L42.2142 32.9135C41.8059 33.2051 41.2593 33.2051 40.8543 32.9084C40.4494 32.6151 40.2794 32.0935 40.4294 31.6186L42.2142 25.859L37.4729 22.5176C37.0696 22.221 36.903 21.6977 37.0563 21.2211C37.2113 20.7461 37.6529 20.4228 38.1545 20.4195L44.0274 20.4111L45.8972 14.7949C46.0556 14.32 46.4989 14 47.0005 14C47.5004 14 47.9454 14.32 48.1037 14.7949L49.9419 20.4111L55.8448 20.4195C56.3464 20.4228 56.7897 20.7461 56.943 21.2211C57.098 21.6977 56.9297 22.2193 56.5264 22.5176L51.7834 25.859L53.5683 31.6186C53.7216 32.0952 53.5483 32.6151 53.145 32.9084C52.94 33.0584 52.7 33.1318 52.4617 33.1318Z" />
      <path d="M84.4617 33.1318C84.2251 33.1318 83.9884 33.0584 83.7851 32.9135L78.9988 29.4821L74.2142 32.9135C73.8059 33.2051 73.2593 33.2051 72.8543 32.9084C72.4494 32.6151 72.2794 32.0935 72.4294 31.6186L74.2142 25.859L69.4729 22.5176C69.0696 22.221 68.903 21.6977 69.0563 21.2211C69.2113 20.7461 69.6529 20.4228 70.1545 20.4195L76.0274 20.4111L77.8972 14.7949C78.0556 14.32 78.4989 14 79.0005 14C79.5004 14 79.9454 14.32 80.1037 14.7949L81.9419 20.4111L87.8448 20.4195C88.3464 20.4228 88.7897 20.7461 88.943 21.2211C89.098 21.6977 88.9297 22.2193 88.5264 22.5176L83.7834 25.859L85.5683 31.6186C85.7216 32.0952 85.5483 32.6151 85.145 32.9084C84.94 33.0584 84.7 33.1318 84.4617 33.1318Z" />
      <path d="M116.462 33.1318C116.225 33.1318 115.988 33.0584 115.785 32.9135L110.999 29.4821L106.214 32.9135C105.806 33.2051 105.259 33.2051 104.854 32.9084C104.449 32.6151 104.279 32.0935 104.429 31.6186L106.214 25.859L101.473 22.5176C101.07 22.221 100.903 21.6977 101.056 21.2211C101.211 20.7461 101.653 20.4228 102.155 20.4195L108.027 20.4111L109.897 14.7949C110.056 14.32 110.499 14 111 14C111.5 14 111.945 14.32 112.104 14.7949L113.942 20.4111L119.845 20.4195C120.346 20.4228 120.79 20.7461 120.943 21.2211C121.098 21.6977 120.93 22.2193 120.526 22.5176L115.783 25.859L117.568 31.6186C117.722 32.0952 117.548 32.6151 117.145 32.9084C116.94 33.0584 116.7 33.1318 116.462 33.1318Z" />
      <path d="M17.84 18.308H17.174C16.466 18.308 15.95 18.476 15.626 18.812C15.302 19.136 15.14 19.664 15.14 20.396V21.926C15.14 22.202 15.122 22.466 15.086 22.718C15.062 22.958 15.002 23.186 14.906 23.402C14.81 23.618 14.672 23.822 14.492 24.014C14.312 24.206 14.072 24.374 13.772 24.518C14.072 24.662 14.312 24.83 14.492 25.022C14.672 25.202 14.81 25.4 14.906 25.616C15.002 25.832 15.062 26.066 15.086 26.318C15.122 26.558 15.14 26.81 15.14 27.074V28.604C15.14 29.336 15.302 29.864 15.626 30.188C15.95 30.524 16.466 30.692 17.174 30.692H17.84V32.6H16.634C14.198 32.6 12.98 31.382 12.98 28.946V27.326C12.98 26.678 12.842 26.216 12.566 25.94C12.302 25.664 11.96 25.496 11.54 25.436V23.564C11.96 23.504 12.302 23.342 12.566 23.078C12.842 22.802 12.98 22.34 12.98 21.692V20.054C12.98 17.618 14.198 16.4 16.634 16.4H17.84V18.308Z" />
      <path d="M140.135 30.692H140.801C141.509 30.692 142.025 30.524 142.349 30.188C142.673 29.864 142.835 29.336 142.835 28.604V27.074C142.835 26.798 142.847 26.54 142.871 26.3C142.907 26.048 142.973 25.814 143.069 25.598C143.165 25.382 143.303 25.178 143.483 24.986C143.663 24.794 143.903 24.626 144.203 24.482C143.903 24.338 143.663 24.176 143.483 23.996C143.303 23.804 143.165 23.6 143.069 23.384C142.973 23.168 142.907 22.94 142.871 22.7C142.847 22.448 142.835 22.19 142.835 21.926V20.396C142.835 19.664 142.673 19.136 142.349 18.812C142.025 18.476 141.509 18.308 140.801 18.308H140.135V16.4H141.341C143.777 16.4 144.995 17.618 144.995 20.054V21.674C144.995 22.322 145.127 22.784 145.391 23.06C145.667 23.336 146.015 23.504 146.435 23.564V25.436C146.015 25.496 145.667 25.664 145.391 25.94C145.127 26.204 144.995 26.66 144.995 27.308V28.946C144.995 31.382 143.777 32.6 141.341 32.6H140.135V30.692Z" />
    </GoldSVG>
  )
}
