export default function EditIcon({ width = 24, height = 24 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 20C21.5523 20 22 20.4477 22 21C22 21.5523 21.5523 22 21 22H3C2.44772 22 2 21.5523 2 21C2 20.4477 2.44772 20 3 20H21ZM6.29289 13.2929L17.2929 2.29289C17.6534 1.93241 18.2206 1.90468 18.6129 2.2097L18.7071 2.29289L21.7071 5.29289C22.0676 5.65338 22.0953 6.22061 21.7903 6.6129L21.7071 6.70711L10.7071 17.7071C10.5508 17.8634 10.3481 17.9626 10.1314 17.9913L10 18H7C6.48716 18 6.06449 17.614 6.00673 17.1166L6 17V14C6 13.779 6.07316 13.5655 6.20608 13.392L6.29289 13.2929L17.2929 2.29289L6.29289 13.2929ZM18 4.41421L8 14.4142V16H9.58579L19.5858 6L18 4.41421Z"
      />
    </svg>
  )
}
