import { useTheme } from "@/store/hooks"

import { UnknownSVG } from "./styled"

export default function RankObsolete() {
  const theme = useTheme()

  return (
    <UnknownSVG
      width="158"
      height="48"
      viewBox="0 0 158 48"
      xmlns="http://www.w3.org/2000/svg"
      $theme={theme}
    >
      <rect width="158" height="48" rx="8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79 33C83.9706 33 88 28.9706 88 24C88 19.0294 83.9706 15 79 15C74.0294 15 70 19.0294 70 24C70 28.9706 74.0294 33 79 33ZM79 31C75.134 31 72 27.866 72 24C72 20.134 75.134 17 79 17C82.866 17 86 20.134 86 24C86 27.866 82.866 31 79 31ZM79 19C79.5523 19 80 19.4477 80 20V22.5858L81.7071 24.2929C82.0976 24.6834 82.0976 25.3166 81.7071 25.7071C81.3166 26.0976 80.6834 26.0976 80.2929 25.7071L78.2929 23.7071C78.1054 23.5196 78 23.2652 78 23V20C78 19.4477 78.4477 19 79 19Z"
      />
    </UnknownSVG>
  )
}
