export default function ArrowUpIcon() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.3871 10.7903L4.29289 10.7071C3.93241 10.3466 3.90468 9.77939 4.2097 9.3871L4.29289 9.29289L11.2929 2.29289L11.3369 2.2515L11.4047 2.19633L11.5159 2.12467L11.6287 2.07123L11.734 2.03585L11.8819 2.00691L12 2L12.0752 2.00279L12.2007 2.02024L12.3121 2.04974L12.4232 2.09367L12.5207 2.146L12.6254 2.21969L12.7071 2.29289L19.7071 9.29289C20.0976 9.68342 20.0976 10.3166 19.7071 10.7071C19.3466 11.0676 18.7794 11.0953 18.3871 10.7903L18.2929 10.7071L13 5.416V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V5.414L5.70711 10.7071C5.34662 11.0676 4.77939 11.0953 4.3871 10.7903L4.29289 10.7071L4.3871 10.7903Z"
      />
    </svg>
  )
}
