import React from "react"

interface TelegramBubbleProps {
  onClick?: React.MouseEventHandler<SVGElement>
  className?: string
}

export default function TelegramBubble({
  onClick = undefined,
  className = undefined,
}: TelegramBubbleProps) {
  return (
    <svg
      fill="none"
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      <rect height="30" width="30" fill="#69A8E5" rx="6.818" />
      <g clipPath="url(#a)">
        <path
          d="M8.488 14.585c3.94-1.653 6.566-2.742 7.88-3.268 3.753-1.503 4.533-1.764 5.041-1.772.112-.002.362.024.524.15a.541.541 0 01.192.353c.018.101.04.332.023.512-.204 2.057-1.084 7.049-1.531 9.353-.19.975-.566 1.183-.927 1.215-.784.07-1.378-.38-2.138-.86-1.189-.751-1.626-.953-2.78-1.686-1.335-.846-.607-1.289.154-2.05.199-.198 3.68-3.297 3.748-3.572.008-.034-.107-.378-.186-.445-.079-.068-.195-.045-.28-.027-.119.027-2.019 1.236-5.7 3.627-.538.357-1.027.53-1.465.521-.482-.01-1.41-.262-2.1-.478-.846-.265-1.159-.39-1.1-.84.03-.234.245-.478.645-.733z"
          fill="#fff"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path
            d="M0 0h16.364v16.364H0z"
            fill="#fff"
            transform="translate(7.162 6.818)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
