import Cookies from "js-cookie"

import { FALLBACK_LANGUAGE, LOCALE_COOKIE } from "~/app/utils/i18n"

export const supportedLanguages: SupportedLanguagesT[] = ["ru", "en"]
export type SupportedLanguagesT = "ru" | "en"

export function isSupportedLanguage(lang: string): lang is SupportedLanguagesT {
  return supportedLanguages.includes(lang as SupportedLanguagesT)
}

export function getClientSavedLocale(): SupportedLanguagesT {
  if (process.env.SSR) return FALLBACK_LANGUAGE

  const cookie = Cookies.get(LOCALE_COOKIE) ?? ""

  if (isSupportedLanguage(cookie)) return cookie

  return FALLBACK_LANGUAGE
}
