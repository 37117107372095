import { useCallback } from "react"

import { useSearchParams } from "react-router-dom"
import styled from "styled-components"

import Modal from "@/components/Modal"
import LoginFlow from "@/features/auth/LoginFlow"
import { useAppSelector } from "@/store"

export default function LoginModal() {
  const [params, setParams] = useSearchParams()

  const isLoggedIn = useAppSelector(({ user }) => Boolean(user.token))

  const close = useCallback(
    () =>
      setParams(prev => {
        prev.delete("auth")
        return prev
      }),
    [setParams],
  )

  const isOpen = !isLoggedIn && params.has("auth")

  return (
    <Root isOpen={isOpen} close={close}>
      <LoginFlow />
    </Root>
  )
}

const Root = styled(Modal)`
  max-width: 574px;
`
