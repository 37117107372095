import { useTheme } from "@/store/hooks"

import { PlatinumSVG } from "./styled"

export default function RankPlatinumTwo() {
  const theme = useTheme()

  return (
    <PlatinumSVG
      width="158"
      height="48"
      viewBox="0 0 158 48"
      xmlns="http://www.w3.org/2000/svg"
      $theme={theme}
      $rank={1}
    >
      <rect x="1" y="1" width="156" height="46" rx="7" />
      <circle cx="79" cy="24" r="14" />
      <path d="M22.8232 30.3922C22.6575 30.3922 22.4919 30.3409 22.3496 30.2394L18.9992 27.8374L15.6499 30.2394C15.3641 30.4436 14.9815 30.4436 14.698 30.2359C14.4145 30.0306 14.2956 29.6655 14.4005 29.333L15.6499 25.3013L12.3311 22.9623C12.0487 22.7547 11.9321 22.3884 12.0394 22.0548C12.1479 21.7223 12.457 21.496 12.8082 21.4936L16.9192 21.4878L18.2281 17.5565C18.3389 17.224 18.6492 17 19.0003 17C19.3503 17 19.6618 17.224 19.7726 17.5565L21.0593 21.4878L25.1913 21.4936C25.5425 21.496 25.8528 21.7223 25.9601 22.0548C26.0686 22.3884 25.9508 22.7535 25.6685 22.9623L22.3484 25.3013L23.5978 29.333C23.7051 29.6666 23.5838 30.0306 23.3015 30.2359C23.158 30.3409 22.99 30.3922 22.8232 30.3922Z" />
      <path d="M142.823 30.3922C142.658 30.3922 142.492 30.3409 142.35 30.2394L138.999 27.8374L135.65 30.2394C135.364 30.4436 134.982 30.4436 134.698 30.2359C134.415 30.0306 134.296 29.6655 134.401 29.333L135.65 25.3013L132.331 22.9623C132.049 22.7547 131.932 22.3884 132.039 22.0548C132.148 21.7223 132.457 21.496 132.808 21.4936L136.919 21.4878L138.228 17.5565C138.339 17.224 138.649 17 139 17C139.35 17 139.662 17.224 139.773 17.5565L141.059 21.4878L145.191 21.4936C145.542 21.496 145.853 21.7223 145.96 22.0548C146.069 22.3884 145.951 22.7535 145.668 22.9623L142.348 25.3013L143.598 29.333C143.705 29.6666 143.584 30.0306 143.301 30.2359C143.158 30.3409 142.99 30.3922 142.823 30.3922Z" />
      <path
        d="M79 34.5C84.799 34.5 89.5 29.799 89.5 24C89.5 18.201 84.799 13.5 79 13.5C73.201 13.5 68.5 18.201 68.5 24C68.5 29.799 73.201 34.5 79 34.5Z"
        className="inner-circle"
      />
      <path d="M82.8232 31C82.6575 31 82.4919 30.9463 82.3496 30.8402L78.9992 28.3293L75.6499 30.8402C75.3641 31.0537 74.9815 31.0537 74.698 30.8366C74.4145 30.622 74.2956 30.2402 74.4005 29.8927L75.6499 25.678L72.3311 23.2329C72.0487 23.0159 71.9321 22.6329 72.0394 22.2841C72.1479 21.9366 72.457 21.7 72.8082 21.6976L76.9192 21.6915L78.2281 17.5817C78.3389 17.2341 78.6492 17 79.0003 17C79.3503 17 79.6618 17.2341 79.7726 17.5817L81.0593 21.6915L85.1913 21.6976C85.5425 21.7 85.8528 21.9366 85.9601 22.2841C86.0686 22.6329 85.9508 23.0146 85.6685 23.2329L82.3484 25.678L83.5978 29.8927C83.7051 30.2415 83.5838 30.622 83.3015 30.8366C83.158 30.9463 82.99 31 82.8232 31Z" />

      <rect
        x="1"
        y="1"
        width="156"
        height="46"
        rx="7"
        stroke="url(#grad)"
        stroke-width="2"
        fill="none"
      />

      <defs>
        <linearGradient
          id="grad"
          x1="158"
          y1="48"
          x2="-3.78942e-07"
          y2="1.24735e-06"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--grad-start)" />
          <stop offset="1" stopColor="var(--grad-stop)" />
        </linearGradient>
      </defs>
    </PlatinumSVG>
  )
}
