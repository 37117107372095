interface IRoflProps {
  width?: number
  height?: number
  className?: string
}

export default function Rofl({
  width = 100,
  height = 100,
  className = "",
}: IRoflProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_10732_18429)">
        <path
          d="M85.3556 14.6442C104.881 34.1692 104.881 65.8303 85.3556 85.3553C65.8306 104.88 34.1723 104.88 14.6445 85.3553C-4.88052 65.8303 -4.88052 34.1692 14.6445 14.6442C34.1695 -4.88076 65.8278 -4.88076 85.3556 14.6442"
          fill="#FFCB4C"
        />
        <path
          d="M53.5527 53.5557C43.8471 63.2612 36.2777 68.5668 26.761 74.9807C24.5915 76.4501 21.4054 80.339 26.7638 85.7001C37.4777 96.414 63.1832 97.4973 80.3388 80.3418C97.4971 63.1834 96.4165 37.4807 85.6971 26.764C80.3388 21.4029 76.4471 24.5918 74.9804 26.7612C68.5693 36.2751 63.261 43.8473 53.5527 53.5557V53.5557Z"
          fill="#65471B"
        />
        <path
          d="M32.1223 80.3415C32.1223 80.3415 42.839 74.9832 58.9112 58.9082C74.9862 42.836 80.3418 32.1221 80.3418 32.1221C80.3418 32.1221 85.6973 48.1971 66.9473 66.9471C48.1973 85.6998 32.1223 80.3415 32.1223 80.3415V80.3415Z"
          fill="white"
        />
        <path
          d="M67.4972 18.4862C67.1055 18.5418 60.9055 19.4779 53.7667 23.0085C53.5944 22.7279 53.4 22.4585 53.1583 22.2168C52.4333 21.4918 51.5055 21.1085 50.5583 21.0307C53.0778 16.4668 56.0528 13.4279 56.1083 13.3751C57.1861 12.2918 57.1861 10.5307 56.1 9.45013C55.0139 8.36957 53.2611 8.3668 52.1778 9.45013C51.7444 9.88346 41.5444 20.2307 41.5444 33.0196C41.5444 34.1446 42.2194 35.1557 43.2583 35.5862C44.2944 36.0168 45.4916 35.7779 46.2861 34.9835C55.3 25.9696 68.1583 24.0029 68.2889 23.9835C68.9055 23.8946 69.4444 23.6085 69.8528 23.2001C70.45 22.6029 70.7694 21.739 70.6416 20.839C70.4166 19.3196 69.0139 18.2696 67.4972 18.4862V18.4862ZM32.5333 42.0279C19.7444 42.0279 9.3972 52.2279 8.96387 52.6612C7.88053 53.7446 7.88053 55.5001 8.96387 56.5835C10.0444 57.6696 11.8028 57.6723 12.8916 56.5918C12.9444 56.539 15.9833 53.5612 20.5472 51.0446C20.6278 51.9918 21.0083 52.9196 21.7333 53.6446C21.975 53.8862 22.2444 54.0807 22.525 54.2529C18.9916 61.389 18.0555 67.5918 18 67.9835C17.7833 69.5029 18.8389 70.9085 20.3555 71.1251C21.2583 71.2557 22.1166 70.9362 22.7139 70.339C23.1222 69.9307 23.4083 69.389 23.4972 68.7696C23.5166 68.6418 25.4361 55.8335 34.4972 46.7723C35.2916 45.9779 35.5278 44.7835 35.1 43.7446C34.6694 42.7029 33.6583 42.0279 32.5333 42.0279V42.0279Z"
          fill="#65471B"
        />
        <path
          d="M83.6139 33.4859C91.2278 34.4137 98.1528 28.9914 99.0778 21.3748C100.006 13.7609 94.5861 6.83589 86.9695 5.90811C79.3556 4.98311 58.0556 13.5831 57.7195 16.3414C57.3834 19.097 76 32.5553 83.6139 33.4859V33.4859ZM33.7695 83.3303C34.6972 90.9442 29.275 97.8692 21.6584 98.7942C14.0445 99.722 7.11947 94.3026 6.19169 86.6859C5.26392 79.0692 13.8667 57.7692 16.625 57.4331C19.3806 57.0998 32.8389 75.7164 33.7695 83.3303V83.3303Z"
          fill="#64AADD"
        />
      </g>
      <defs>
        <clipPath id="clip0_10732_18429">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
