export default function LongCheckmark() {
  return (
    <svg
      width="23"
      height="16"
      viewBox="0 0 23 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 7.97141L8.39365 14.6063L21 2"
        strokeWidth="2.65397"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
