import { createRoot } from "react-dom/client"
import { HelmetProvider } from "react-helmet-async"
import { RouterProvider, createBrowserRouter } from "react-router-dom"

import App from "@/App"
import ReduxProvider from "@/components/ReduxProvider"
import { routes } from "@/pages"

const router = createBrowserRouter([{ Component: App, children: routes }])

const content = (
  <HelmetProvider>
    <ReduxProvider>
      <RouterProvider router={router} />
    </ReduxProvider>
  </HelmetProvider>
)

const element = document.getElementById("root")!

const root = createRoot(element)
root.render(content)
