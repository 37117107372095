import { useTheme } from "@/store/hooks"

import { GoldSVG } from "./styled"

export default function RankGoldOne() {
  const theme = useTheme()

  return (
    <GoldSVG
      width="158"
      height="48"
      viewBox="0 0 158 48"
      xmlns="http://www.w3.org/2000/svg"
      $theme={theme}
    >
      <rect width="158" height="48" rx="8" />
      <path d="M84.4617 33.1318C84.2251 33.1318 83.9884 33.0584 83.7851 32.9135L78.9988 29.4821L74.2142 32.9135C73.8059 33.2051 73.2593 33.2051 72.8543 32.9084C72.4494 32.6151 72.2794 32.0935 72.4294 31.6186L74.2142 25.859L69.4729 22.5176C69.0696 22.221 68.903 21.6977 69.0563 21.2211C69.2113 20.7461 69.6529 20.4228 70.1545 20.4195L76.0274 20.4111L77.8972 14.7949C78.0556 14.32 78.4989 14 79.0005 14C79.5004 14 79.9454 14.32 80.1037 14.7949L81.9419 20.4111L87.8448 20.4195C88.3464 20.4228 88.7897 20.7461 88.943 21.2211C89.098 21.6977 88.9297 22.2193 88.5264 22.5176L83.7834 25.859L85.5683 31.6186C85.7216 32.0952 85.5483 32.6151 85.145 32.9084C84.94 33.0584 84.7 33.1318 84.4617 33.1318Z" />
      <path d="M17.84 18.308H17.174C16.466 18.308 15.95 18.476 15.626 18.812C15.302 19.136 15.14 19.664 15.14 20.396V21.926C15.14 22.202 15.122 22.466 15.086 22.718C15.062 22.958 15.002 23.186 14.906 23.402C14.81 23.618 14.672 23.822 14.492 24.014C14.312 24.206 14.072 24.374 13.772 24.518C14.072 24.662 14.312 24.83 14.492 25.022C14.672 25.202 14.81 25.4 14.906 25.616C15.002 25.832 15.062 26.066 15.086 26.318C15.122 26.558 15.14 26.81 15.14 27.074V28.604C15.14 29.336 15.302 29.864 15.626 30.188C15.95 30.524 16.466 30.692 17.174 30.692H17.84V32.6H16.634C14.198 32.6 12.98 31.382 12.98 28.946V27.326C12.98 26.678 12.842 26.216 12.566 25.94C12.302 25.664 11.96 25.496 11.54 25.436V23.564C11.96 23.504 12.302 23.342 12.566 23.078C12.842 22.802 12.98 22.34 12.98 21.692V20.054C12.98 17.618 14.198 16.4 16.634 16.4H17.84V18.308Z" />
      <path d="M139.342 30.692H140.008C140.716 30.692 141.232 30.524 141.556 30.188C141.88 29.864 142.042 29.336 142.042 28.604V27.074C142.042 26.798 142.054 26.54 142.078 26.3C142.114 26.048 142.18 25.814 142.276 25.598C142.372 25.382 142.51 25.178 142.69 24.986C142.87 24.794 143.11 24.626 143.41 24.482C143.11 24.338 142.87 24.176 142.69 23.996C142.51 23.804 142.372 23.6 142.276 23.384C142.18 23.168 142.114 22.94 142.078 22.7C142.054 22.448 142.042 22.19 142.042 21.926V20.396C142.042 19.664 141.88 19.136 141.556 18.812C141.232 18.476 140.716 18.308 140.008 18.308H139.342V16.4H140.548C142.984 16.4 144.202 17.618 144.202 20.054V21.674C144.202 22.322 144.334 22.784 144.598 23.06C144.874 23.336 145.222 23.504 145.642 23.564V25.436C145.222 25.496 144.874 25.664 144.598 25.94C144.334 26.204 144.202 26.66 144.202 27.308V28.946C144.202 31.382 142.984 32.6 140.548 32.6H139.342V30.692Z" />
    </GoldSVG>
  )
}
