export default function ReloadIcon({ width = 18, height = 18 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.53677 4.00628L9.25105 2.25628C9.58579 1.91457 10.1285 1.91457 10.4632 2.25628C10.7722 2.57171 10.796 3.06803 10.5345 3.41129L10.4632 3.49372L10.11 3.8546C12.8934 4.38607 15 6.8794 15 9.875C15 13.2577 12.3137 16 9 16C5.68629 16 3 13.2577 3 9.875C3 8.95566 3.19913 8.06343 3.57772 7.24978C3.78083 6.81327 4.29212 6.62749 4.71972 6.83483C5.14732 7.04217 5.32931 7.56411 5.1262 8.00062C4.85621 8.58088 4.71429 9.21678 4.71429 9.875C4.71429 12.2912 6.63307 14.25 9 14.25C11.3669 14.25 13.2857 12.2912 13.2857 9.875C13.2857 7.98655 12.1136 6.37755 10.4716 5.76473C10.7979 6.10784 10.795 6.65505 10.4632 6.99372C10.1542 7.30914 9.66805 7.33341 9.3318 7.06651L9.25105 6.99372L7.53677 5.24372C7.22778 4.92829 7.20401 4.43197 7.46546 4.08871L7.53677 4.00628L9.25105 2.25628L7.53677 4.00628Z"
      ></path>
    </svg>
  )
}
