import { useTheme } from "@/store/hooks"

import { PlatinumSVG } from "./styled"

export default function RankPlatinumOne() {
  const theme = useTheme()

  return (
    <PlatinumSVG
      width="158"
      height="48"
      viewBox="0 0 158 48"
      xmlns="http://www.w3.org/2000/svg"
      $theme={theme}
      $rank={0}
    >
      <rect x="1" y="1" width="156" height="46" rx="7" />
      <circle cx="79" cy="24" r="14" />
      <path
        d="M79 34.5C84.799 34.5 89.5 29.799 89.5 24C89.5 18.201 84.799 13.5 79 13.5C73.201 13.5 68.5 18.201 68.5 24C68.5 29.799 73.201 34.5 79 34.5Z"
        className="inner-circle"
      />
      <path d="M82.8232 31C82.6575 31 82.4919 30.9463 82.3496 30.8402L78.9992 28.3293L75.6499 30.8402C75.3641 31.0537 74.9815 31.0537 74.698 30.8366C74.4145 30.622 74.2956 30.2402 74.4005 29.8927L75.6499 25.678L72.3311 23.2329C72.0487 23.0159 71.9321 22.6329 72.0394 22.2841C72.1479 21.9366 72.457 21.7 72.8082 21.6976L76.9192 21.6915L78.2281 17.5817C78.3389 17.2341 78.6492 17 79.0003 17C79.3503 17 79.6618 17.2341 79.7726 17.5817L81.0593 21.6915L85.1913 21.6976C85.5425 21.7 85.8528 21.9366 85.9601 22.2841C86.0686 22.6329 85.9508 23.0146 85.6685 23.2329L82.3484 25.678L83.5978 29.8927C83.7051 30.2415 83.5838 30.622 83.3015 30.8366C83.158 30.9463 82.99 31 82.8232 31Z" />
      <path d="M17.84 18.308H17.174C16.466 18.308 15.95 18.476 15.626 18.812C15.302 19.136 15.14 19.664 15.14 20.396V21.926C15.14 22.202 15.122 22.466 15.086 22.718C15.062 22.958 15.002 23.186 14.906 23.402C14.81 23.618 14.672 23.822 14.492 24.014C14.312 24.206 14.072 24.374 13.772 24.518C14.072 24.662 14.312 24.83 14.492 25.022C14.672 25.202 14.81 25.4 14.906 25.616C15.002 25.832 15.062 26.066 15.086 26.318C15.122 26.558 15.14 26.81 15.14 27.074V28.604C15.14 29.336 15.302 29.864 15.626 30.188C15.95 30.524 16.466 30.692 17.174 30.692H17.84V32.6H16.634C14.198 32.6 12.98 31.382 12.98 28.946V27.326C12.98 26.678 12.842 26.216 12.566 25.94C12.302 25.664 11.96 25.496 11.54 25.436V23.564C11.96 23.504 12.302 23.342 12.566 23.078C12.842 22.802 12.98 22.34 12.98 21.692V20.054C12.98 17.618 14.198 16.4 16.634 16.4H17.84V18.308Z" />
      <path d="M140.135 30.692H140.801C141.509 30.692 142.025 30.524 142.349 30.188C142.673 29.864 142.835 29.336 142.835 28.604V27.074C142.835 26.798 142.847 26.54 142.871 26.3C142.907 26.048 142.973 25.814 143.069 25.598C143.165 25.382 143.303 25.178 143.483 24.986C143.663 24.794 143.903 24.626 144.203 24.482C143.903 24.338 143.663 24.176 143.483 23.996C143.303 23.804 143.165 23.6 143.069 23.384C142.973 23.168 142.907 22.94 142.871 22.7C142.847 22.448 142.835 22.19 142.835 21.926V20.396C142.835 19.664 142.673 19.136 142.349 18.812C142.025 18.476 141.509 18.308 140.801 18.308H140.135V16.4H141.341C143.777 16.4 144.995 17.618 144.995 20.054V21.674C144.995 22.322 145.127 22.784 145.391 23.06C145.667 23.336 146.015 23.504 146.435 23.564V25.436C146.015 25.496 145.667 25.664 145.391 25.94C145.127 26.204 144.995 26.66 144.995 27.308V28.946C144.995 31.382 143.777 32.6 141.341 32.6H140.135V30.692Z" />

      <rect
        x="1"
        y="1"
        width="156"
        height="46"
        rx="7"
        stroke="url(#grad)"
        stroke-width="2"
        fill="none"
      />

      <defs>
        <linearGradient
          id="grad"
          x1="158"
          y1="48"
          x2="-3.78942e-07"
          y2="1.24735e-06"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--grad-start)" />
          <stop offset="1" stopColor="var(--grad-stop)" />
        </linearGradient>
      </defs>
    </PlatinumSVG>
  )
}
