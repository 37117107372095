interface IFileEditIconProps {
  width?: number
  height?: number
  className?: string
}

export default function FileEditIcon({
  width = 18,
  height = 18,
  className = "",
}: IFileEditIconProps) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 1.5C10.6989 1.5 10.8897 1.57902 11.0303 1.71967L14.7803 5.46967C14.921 5.61032 15 5.80109 15 6V6.75C15 7.16421 14.6642 7.5 14.25 7.5H9.75C9.36537 7.5 9.04837 7.21047 9.00505 6.83747L9 6.75L8.99925 3H5.25C4.83579 3 4.5 3.33579 4.5 3.75V14.25C4.5 14.6642 4.83579 15 5.25 15H6.75C7.16421 15 7.5 15.3358 7.5 15.75C7.5 16.1642 7.16421 16.5 6.75 16.5H5.25C4.00736 16.5 3 15.4926 3 14.25V3.75C3 2.50736 4.00736 1.5 5.25 1.5H10.5ZM12.9697 9.21967C13.24 8.94931 13.6655 8.92851 13.9597 9.15728L14.0303 9.21967L16.2803 11.4697C16.5507 11.74 16.5715 12.1655 16.3427 12.4597L16.2803 12.5303L12.5303 16.2803C12.4131 16.3975 12.2611 16.4719 12.0986 16.4935L12 16.5H9.75C9.36537 16.5 9.04837 16.2105 9.00505 15.8375L9 15.75V13.5C9 13.3342 9.05487 13.1741 9.15456 13.044L9.21967 12.9697L12.9697 9.21967ZM13.5 10.8105L10.5 13.8105V14.9999H11.6893L14.6893 11.9999L13.5 10.8105ZM10.4992 3.31055L10.5 6.00005H13.1895L10.4992 3.31055Z"
      />
    </svg>
  )
}
