import { MouseEventHandler } from "react"

interface IThemeSwitcherProps {
  width?: number
  height?: number
  onClick?: MouseEventHandler<SVGElement>
}

export default function ThemeSwitcher({
  width = 24,
  height = 24,
  onClick = () => {},
}: IThemeSwitcherProps) {
  return (
    <svg
      onClick={onClick}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        data-kind="background"
        d="M12.7071 2.29289L12.6129 2.2097C12.2206 1.90468 11.6534 1.93241 11.2929 2.29289L8.584 5H6L5.88338 5.00673C5.38604 5.06449 5 5.48716 5 6V8.584L2.29289 11.2929L2.2097 11.3871C1.90468 11.7794 1.93241 12.3466 2.29289 12.7071L5 15.415V18L5.00673 18.1166C5.06449 18.614 5.48716 19 6 19H8.585L11.2929 21.7071L11.3871 21.7903C11.7794 22.0953 12.3466 22.0676 12.7071 21.7071L15.414 19H18L18.1166 18.9933C18.614 18.9355 19 18.5128 19 18V15.414L21.7071 12.7071L21.7903 12.6129C22.0953 12.2206 22.0676 11.6534 21.7071 11.2929L19 8.585V6L18.9933 5.88338C18.9355 5.38604 18.5128 5 18 5H15.415L12.7071 2.29289ZM17 12C17 14.7614 14.7614 17 12 17V7C14.7614 7 17 9.23858 17 12Z"
      />
    </svg>
  )
}
