import { MouseEventHandler } from "react"

interface IDownloadIconProps {
  width?: number
  height?: number
  onClick: MouseEventHandler<SVGSVGElement>
  load: "stdin" | "stdout"
}

export default function DownloadIcon({
  width = 18,
  height = 18,
  onClick,
  load,
}: IDownloadIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      onClick={onClick}
      data-load={load}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3008 14.3998C15.7978 14.3998 16.2008 14.8027 16.2008 15.2998C16.2008 15.7969 15.7978 16.1998 15.3008 16.1998H2.70078C2.20372 16.1998 1.80078 15.7969 1.80078 15.2998C1.80078 14.8027 2.20372 14.3998 2.70078 14.3998H15.3008ZM9.00078 12.5998L8.92698 12.5962L8.82013 12.5816L8.71985 12.555L8.61988 12.5155L8.53214 12.4684L8.46514 12.4231L8.36439 12.3362L5.66439 9.6362C5.31291 9.28473 5.31291 8.71488 5.66439 8.36341C5.98882 8.03897 6.49933 8.01402 6.85239 8.28854L6.93718 8.36341L8.10078 9.52631V2.6998C8.10078 2.20275 8.50373 1.7998 9.00078 1.7998C9.49784 1.7998 9.90078 2.20275 9.90078 2.6998V9.52631L11.0644 8.36341C11.3888 8.03897 11.8993 8.01402 12.2524 8.28854L12.3372 8.36341C12.6616 8.68784 12.6866 9.19835 12.412 9.55141L12.3372 9.6362L9.63718 12.3362L9.59761 12.3735L9.53656 12.4231L9.43644 12.4876L9.38157 12.5155L9.30827 12.5459L9.24018 12.5675L9.10651 12.5937L9.00078 12.5998Z"
      />
    </svg>
  )
}
