import { useTheme } from "@/store/hooks"

import { GoldSVG } from "./styled"

export default function RankGoldTwo() {
  const theme = useTheme()

  return (
    <GoldSVG
      width="158"
      height="48"
      viewBox="0 0 158 48"
      xmlns="http://www.w3.org/2000/svg"
      $theme={theme}
    >
      <rect width="158" height="48" rx="8" />
      <path d="M68.4617 33.1318C68.2251 33.1318 67.9884 33.0584 67.7851 32.9135L62.9988 29.4821L58.2142 32.9135C57.8059 33.2051 57.2593 33.2051 56.8543 32.9084C56.4494 32.6151 56.2794 32.0935 56.4294 31.6186L58.2142 25.859L53.4729 22.5176C53.0696 22.221 52.903 21.6977 53.0563 21.2211C53.2113 20.7461 53.6529 20.4228 54.1545 20.4195L60.0274 20.4111L61.8972 14.7949C62.0556 14.32 62.4989 14 63.0005 14C63.5004 14 63.9454 14.32 64.1037 14.7949L65.9419 20.4111L71.8448 20.4195C72.3464 20.4228 72.7897 20.7461 72.943 21.2211C73.098 21.6977 72.9297 22.2193 72.5264 22.5176L67.7834 25.859L69.5683 31.6186C69.7216 32.0952 69.5483 32.6151 69.145 32.9084C68.94 33.0584 68.7 33.1318 68.4617 33.1318Z" />
      <path d="M100.462 33.1318C100.225 33.1318 99.9884 33.0584 99.7851 32.9135L94.9988 29.4821L90.2142 32.9135C89.8059 33.2051 89.2593 33.2051 88.8543 32.9084C88.4494 32.6151 88.2794 32.0935 88.4294 31.6186L90.2142 25.859L85.4729 22.5176C85.0696 22.221 84.903 21.6977 85.0563 21.2211C85.2113 20.7461 85.6529 20.4228 86.1545 20.4195L92.0274 20.4111L93.8972 14.7949C94.0556 14.32 94.4989 14 95.0005 14C95.5004 14 95.9454 14.32 96.1037 14.7949L97.9419 20.4111L103.845 20.4195C104.346 20.4228 104.79 20.7461 104.943 21.2211C105.098 21.6977 104.93 22.2193 104.526 22.5176L99.7834 25.859L101.568 31.6186C101.722 32.0952 101.548 32.6151 101.145 32.9084C100.94 33.0584 100.7 33.1318 100.462 33.1318Z" />
      <path d="M17.84 18.308H17.174C16.466 18.308 15.95 18.476 15.626 18.812C15.302 19.136 15.14 19.664 15.14 20.396V21.926C15.14 22.202 15.122 22.466 15.086 22.718C15.062 22.958 15.002 23.186 14.906 23.402C14.81 23.618 14.672 23.822 14.492 24.014C14.312 24.206 14.072 24.374 13.772 24.518C14.072 24.662 14.312 24.83 14.492 25.022C14.672 25.202 14.81 25.4 14.906 25.616C15.002 25.832 15.062 26.066 15.086 26.318C15.122 26.558 15.14 26.81 15.14 27.074V28.604C15.14 29.336 15.302 29.864 15.626 30.188C15.95 30.524 16.466 30.692 17.174 30.692H17.84V32.6H16.634C14.198 32.6 12.98 31.382 12.98 28.946V27.326C12.98 26.678 12.842 26.216 12.566 25.94C12.302 25.664 11.96 25.496 11.54 25.436V23.564C11.96 23.504 12.302 23.342 12.566 23.078C12.842 22.802 12.98 22.34 12.98 21.692V20.054C12.98 17.618 14.198 16.4 16.634 16.4H17.84V18.308Z" />
      <path d="M140.135 30.692H140.801C141.509 30.692 142.025 30.524 142.349 30.188C142.673 29.864 142.835 29.336 142.835 28.604V27.074C142.835 26.798 142.847 26.54 142.871 26.3C142.907 26.048 142.973 25.814 143.069 25.598C143.165 25.382 143.303 25.178 143.483 24.986C143.663 24.794 143.903 24.626 144.203 24.482C143.903 24.338 143.663 24.176 143.483 23.996C143.303 23.804 143.165 23.6 143.069 23.384C142.973 23.168 142.907 22.94 142.871 22.7C142.847 22.448 142.835 22.19 142.835 21.926V20.396C142.835 19.664 142.673 19.136 142.349 18.812C142.025 18.476 141.509 18.308 140.801 18.308H140.135V16.4H141.341C143.777 16.4 144.995 17.618 144.995 20.054V21.674C144.995 22.322 145.127 22.784 145.391 23.06C145.667 23.336 146.015 23.504 146.435 23.564V25.436C146.015 25.496 145.667 25.664 145.391 25.94C145.127 26.204 144.995 26.66 144.995 27.308V28.946C144.995 31.382 143.777 32.6 141.341 32.6H140.135V30.692Z" />
    </GoldSVG>
  )
}
