interface ICodeforcesProps {
  width?: number
  height?: number
  className?: string
}

export default function Codeforces({
  width = 115,
  height = 90,
  className = "",
}: ICodeforcesProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 115 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M23.3916 25.3037H6.948C3.088 25.3037 0 28.4763 0 32.268L0 82.3335C0 86.2025 3.1652 89.2978 6.948 89.2978H23.3916C27.2516 89.2978 30.3396 86.1251 30.3396 82.3335V32.268C30.4168 28.3989 27.2516 25.3037 23.3916 25.3037Z"
        fill="url(#paint0_linear_10733_18435)"
      />
      <path
        d="M65.3115 0L48.8679 0C45.0079 0 41.9199 3.17262 41.9199 6.96429V82.3333C41.9199 86.2024 45.0851 89.2976 48.8679 89.2976H65.3115C69.1715 89.2976 72.2595 86.125 72.2595 82.3333V6.96429C72.2595 3.17262 69.1715 0 65.3115 0Z"
        fill="url(#paint1_linear_10733_18435)"
      />
      <path
        d="M107.076 34.8989H90.6326C86.7726 34.8989 83.6846 38.0715 83.6846 41.8632V82.3335C83.6846 86.2025 86.8498 89.2977 90.6326 89.2977H107.076C110.936 89.2977 114.024 86.1251 114.024 82.3335V41.8632C114.024 37.9942 110.936 34.8989 107.076 34.8989Z"
        fill="url(#paint2_linear_10733_18435)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10733_18435"
          x1="0"
          y1="57.3266"
          x2="30.3979"
          y2="57.3266"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6C43D" />
          <stop offset="1" stopColor="#FCD975" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_10733_18435"
          x1="41.8833"
          y1="44.6845"
          x2="72.2813"
          y2="44.6845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1480C4" />
          <stop offset="1" stopColor="#1C99D4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_10733_18435"
          x1="83.6601"
          y1="62.1236"
          x2="114.058"
          y2="62.1236"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B11E26" />
          <stop offset="1" stopColor="#C21C24" />
        </linearGradient>
      </defs>
    </svg>
  )
}
