export default function NoEntryIcon({ width = 36, height = 36 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36 18C36 27.941 27.941 36 18 36C8.059 36 0 27.941 0 18C0 8.059 8.059 0 18 0C27.941 0 36 8.059 36 18Z"
        fill="#BE1931"
      />
      <path
        d="M32 20C32 21.104 31.104 22 30 22H6C4.896 22 4 21.104 4 20V16C4 14.896 4.896 14 6 14H30C31.104 14 32 14.896 32 16V20Z"
        fill="white"
      />
    </svg>
  )
}
