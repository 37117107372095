export default function Cat({ width = 20, height = 20 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M19.8521 11.0717C19.6526 9.25558 19.4443 8.33336 18.8887 7.22225C18.8887 7.22225 20.5554 2.22225 19.4443 0.166696C19.0698 -0.525527 15.331 1.10447 13.8887 2.22225C13.8887 2.22225 11.6665 1.66781 9.99985 1.66781C8.33318 1.66781 6.11096 2.22225 6.11096 2.22225C4.66874 1.10447 0.929851 -0.525527 0.555406 0.166696C-0.555705 2.22225 1.11096 7.22225 1.11096 7.22225C0.555406 8.33336 0.347073 9.25558 0.147628 11.0717C-0.071816 13.0628 0.364851 14.65 0.555406 15C0.772073 15.3978 1.87041 16.6806 3.33318 17.7778C5.55541 19.4445 8.88874 20 9.99985 20C11.111 20 14.4443 19.4445 16.6665 17.7778C18.1293 16.6806 19.2276 15.3978 19.4443 15C19.6348 14.65 20.0715 13.0628 19.8521 11.0717Z"
          fill="#FFCC4D"
        ></path>
        <path
          d="M10.0002 10.8334C11.6668 10.8334 11.6668 11.1112 11.6668 11.6667C11.6668 12.2223 10.8335 13.3334 10.0002 13.3334C9.16683 13.3334 8.3335 12.2223 8.3335 11.6667C8.3335 11.1112 8.33294 10.8334 10.0002 10.8334Z"
          fill="#E75A70"
        ></path>
        <path
          d="M1.11112 1.66672C1.35946 0.921166 4.24446 2.22228 4.80001 2.77783C4.80001 2.77783 2.22223 4.4445 1.66668 6.11117C1.66668 6.11117 0.555566 3.33339 1.11112 1.66672ZM18.8889 1.66672C18.6406 0.921166 15.7556 2.22228 15.2 2.77783C15.2 2.77783 17.7778 4.4445 18.3333 6.11117C18.3333 6.11117 19.4445 3.33339 18.8889 1.66672Z"
          fill="#F18F26"
        ></path>
        <path
          d="M2.74121 3.11278C2.74121 2.32722 6.11121 3.88889 5.55566 4.44444C5.0001 5 4.44454 5.335 3.88899 5.89055C3.33343 6.44611 2.74121 3.66833 2.74121 3.11278ZM17.259 3.11278C17.259 2.32722 13.889 3.88889 14.4445 4.44444C15.0001 5 15.5557 5.335 16.1112 5.89055C16.6668 6.44611 17.259 3.66833 17.259 3.11278Z"
          fill="#FFCC4D"
        ></path>
        <path
          d="M0.389552 13.8889C0.30733 13.8889 0.226218 13.8527 0.171218 13.7833C0.0762185 13.6627 0.096774 13.4883 0.21733 13.3933C0.30733 13.3227 2.45066 11.66 6.13288 11.9461C6.28566 11.9572 6.40011 12.0911 6.38844 12.2439C6.37677 12.3966 6.24566 12.5222 6.09011 12.4994C2.62733 12.2361 0.581774 13.8139 0.561774 13.83C0.510107 13.8694 0.449552 13.8889 0.389552 13.8889ZM5.31622 11.2983C5.289 11.2983 5.26066 11.2944 5.23344 11.2861C2.57233 10.4544 0.379552 11.0933 0.35733 11.0994C0.210107 11.1444 0.0556629 11.0605 0.0112185 10.9133C-0.0326704 10.7661 0.0506629 10.6116 0.19733 10.5677C0.293441 10.5389 2.57844 9.87497 5.39844 10.7555C5.54511 10.8016 5.62677 10.9572 5.58066 11.1039C5.544 11.2222 5.43455 11.2983 5.31622 11.2983ZM19.6107 13.8889C19.6929 13.8889 19.774 13.8527 19.829 13.7833C19.924 13.6627 19.9034 13.4883 19.7829 13.3933C19.6929 13.3227 17.5496 11.66 13.8673 11.9461C13.7146 11.9572 13.6001 12.0911 13.6118 12.2439C13.6234 12.3966 13.7546 12.5222 13.9101 12.4994C17.3729 12.2361 19.4184 13.8139 19.4384 13.83C19.4901 13.8694 19.5507 13.8889 19.6107 13.8889ZM14.684 11.2983C14.7112 11.2983 14.7396 11.2944 14.7668 11.2861C17.4279 10.4544 19.6207 11.0933 19.6429 11.0994C19.7901 11.1444 19.9446 11.0605 19.989 10.9133C20.0329 10.7661 19.9496 10.6116 19.8029 10.5677C19.7068 10.5389 17.4218 9.87497 14.6018 10.7555C14.4551 10.8016 14.3734 10.9572 14.4196 11.1039C14.4557 11.2222 14.5657 11.2983 14.684 11.2983Z"
          fill="#FEE7B8"
        ></path>
        <path
          d="M14.0706 14.2344C13.9861 14.1611 13.8656 14.1461 13.7644 14.1961L11.5422 15.3072C10.5028 15.8267 7.805 15.8333 7.77778 15.8333C7.62444 15.8333 7.5 15.9578 7.5 16.1111C7.5 16.2139 7.55611 16.3039 7.63889 16.3517V16.3522H7.63944V16.3528C7.67889 16.375 7.72444 16.3878 7.77278 16.3889H11.6661C13.1428 16.3889 13.9378 15.1744 14.1522 14.5322C14.1878 14.4256 14.1556 14.3083 14.0706 14.2344Z"
          fill="#292F33"
        ></path>
        <path
          d="M6.66677 10.0001C7.28042 10.0001 7.77789 9.12954 7.77789 8.05565C7.77789 6.98176 7.28042 6.11121 6.66677 6.11121C6.05313 6.11121 5.55566 6.98176 5.55566 8.05565C5.55566 9.12954 6.05313 10.0001 6.66677 10.0001Z"
          fill="#292F33"
        ></path>
        <path
          d="M13.3333 10.0001C13.9469 10.0001 14.4444 9.12954 14.4444 8.05565C14.4444 6.98176 13.9469 6.11121 13.3333 6.11121C12.7196 6.11121 12.2222 6.98176 12.2222 8.05565C12.2222 9.12954 12.7196 10.0001 13.3333 10.0001Z"
          fill="#292F33"
        ></path>
        <path
          d="M11.6666 6.66671C11.5244 6.66671 11.3822 6.61226 11.2739 6.50393C11.0566 6.28671 11.0566 5.9356 11.2739 5.71837C13.6316 3.3606 16.0105 3.33337 16.1111 3.33337C16.4183 3.33337 16.6666 3.58226 16.6666 3.88893C16.6666 4.19504 16.4194 4.44337 16.1139 4.44449C16.0777 4.44504 14.0655 4.49782 12.0594 6.50393C11.9511 6.61226 11.8089 6.66671 11.6666 6.66671ZM8.3333 6.66671C8.19108 6.66671 8.04886 6.61226 7.94052 6.50393C5.92608 4.49004 3.90608 4.44449 3.88608 4.44449C3.57997 4.44115 3.3333 4.19171 3.33497 3.88615C3.33664 3.58004 3.5833 3.33337 3.88886 3.33337C3.98886 3.33337 6.3683 3.3606 8.72608 5.71837C8.9433 5.9356 8.9433 6.28671 8.72608 6.50393C8.61775 6.61226 8.47552 6.66671 8.3333 6.66671Z"
          fill="#292F33"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" fill="white"></rect>
        </clipPath>
      </defs>
    </svg>
  )
}
