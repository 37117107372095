export default function ThinkingEmoji() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 45.3573C36.5184 45.3573 46.6666 35.209 46.6666 22.6906C46.6666 10.1721 36.5184 0.0239258 24 0.0239258C11.4815 0.0239258 1.33331 10.1721 1.33331 22.6906C1.33331 35.209 11.4815 45.3573 24 45.3573Z"
        fill="#FFCB4C"
      />
      <path
        d="M19.3653 28.0482C19.172 27.8935 19.0213 27.6829 18.9493 27.4295C18.7707 26.8162 19.1227 26.2055 19.736 26.0682C25.7733 24.7069 29.8387 27.8855 30.0107 28.0215C30.5227 28.4295 30.6227 29.1482 30.2413 29.6282C29.86 30.1055 29.1373 30.1629 28.6267 29.7575C28.48 29.6455 25.2627 27.1935 20.384 28.2935C20.0187 28.3749 19.6453 28.2722 19.3653 28.0482Z"
        fill="#65471B"
      />
      <path
        d="M17.492 18.4401C19.0568 18.4401 20.3254 16.8546 20.3254 14.8988C20.3254 12.9429 19.0568 11.3574 17.492 11.3574C15.9272 11.3574 14.6587 12.9429 14.6587 14.8988C14.6587 16.8546 15.9272 18.4401 17.492 18.4401Z"
        fill="#65471B"
      />
      <path
        d="M32.5 19.8561C34.0648 19.8561 35.3334 18.2706 35.3334 16.3148C35.3334 14.3589 34.0648 12.7734 32.5 12.7734C30.9352 12.7734 29.6667 14.3589 29.6667 16.3148C29.6667 18.2706 30.9352 19.8561 32.5 19.8561Z"
        fill="#65471B"
      />
      <path
        d="M23.0347 46.8654C23.0347 46.8654 24.7214 46.3174 24.94 45.0627C25.1707 43.7667 24.108 43.5067 24.108 43.5067C24.108 43.5067 25.496 43.2294 25.6707 41.6721C25.8347 40.2041 24.5227 39.8547 24.5227 39.8547C24.5227 39.8547 25.816 39.3214 25.8774 37.8027C25.928 36.5241 24.5507 35.8987 24.5507 35.8987C24.5507 35.8987 31.268 34.2707 31.9587 34.1107C32.6467 33.9507 33.7187 33.2907 33.384 31.8521C33.052 30.4121 31.7787 30.3614 31.1214 30.5147C30.4627 30.6681 22.1294 32.6027 19.2547 33.2721L17.336 33.7174C16.616 33.8867 16.2894 33.5707 16.7974 33.0347C17.4747 32.3201 17.908 31.5294 18.0587 30.2174C18.2174 28.8374 17.7494 27.1334 17.4814 26.4721C16.9827 25.2441 16.1414 24.2734 15.1694 23.9401C13.6534 23.4201 12.576 24.3681 13.1134 26.0214C13.9187 28.4934 13.3907 30.5214 12.0027 31.7454C8.73735 34.6214 7.21869 36.6721 8.22935 41.0427C9.33335 45.8094 14.0654 48.8774 18.832 47.7734L23.0347 46.8654Z"
        fill="#F19020"
      />
      <path
        d="M12.3947 8.46776C12.176 8.35043 11.9907 8.16909 11.8733 7.93576C11.5853 7.36509 11.82 6.69976 12.3973 6.45309C18.0853 4.01176 22.6627 6.39576 22.8547 6.49843C23.432 6.80643 23.6653 7.49443 23.376 8.03709C23.088 8.57709 22.3893 8.76509 21.812 8.46109C21.648 8.37709 18.036 6.55176 13.4413 8.52376C13.0987 8.66909 12.712 8.63709 12.3947 8.46776ZM28.5747 13.4678C28.3667 13.3371 28.1933 13.1438 28.092 12.9024C27.8427 12.3131 28.1227 11.6664 28.716 11.4571C34.5573 9.40909 38.9627 12.0971 39.1467 12.2131C39.7 12.5598 39.8867 13.2624 39.5627 13.7838C39.24 14.3038 38.528 14.4438 37.9747 14.1011C37.8147 14.0051 34.3387 11.9398 29.6187 13.5958C29.264 13.7171 28.8813 13.6571 28.5747 13.4678Z"
        fill="#65471B"
      />
    </svg>
  )
}
