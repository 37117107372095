import { MouseEventHandler } from "react"

export default function CopyIcon({
  width = 18,
  height = 18,
  onClick,
}: {
  width?: number
  height?: number
  onClick: MouseEventHandler<SVGSVGElement>
}) {
  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.69999 12.5998L2.80495 12.5937C3.21812 12.5458 3.54594 12.2179 3.59393 11.8048L3.59999 11.6998V3.5998H11.7L11.8049 3.59375C12.2526 3.54176 12.6 3.16136 12.6 2.6998C12.6 2.23825 12.2526 1.85785 11.8049 1.80586L11.7 1.7998H2.69999L2.59503 1.80586C2.18186 1.85385 1.85403 2.18167 1.80604 2.59485L1.79999 2.6998V11.6998L1.80604 11.8048C1.85403 12.2179 2.18186 12.5458 2.59503 12.5937L2.69999 12.5998ZM15.3 16.1998L15.4049 16.1937C15.8181 16.1458 16.1459 15.8179 16.1939 15.4048L16.2 15.2998V6.2998L16.1939 6.19485C16.1499 5.8161 15.8708 5.50908 15.5063 5.42357L15.4049 5.40586L15.3 5.3998H6.29999L6.19503 5.40586C5.78185 5.45385 5.45403 5.78167 5.40604 6.19485L5.39999 6.2998V15.2998L5.40604 15.4048C5.45003 15.7835 5.72916 16.0905 6.09363 16.176L6.19503 16.1937L6.29999 16.1998H15.3ZM7.19999 7.1998V14.3998H14.4V7.1998H7.19999Z"
      />
    </svg>
  )
}
