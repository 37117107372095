import { useMemo } from "react"

import { Duration } from "luxon"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { IContestTimings } from "@/store/api/contests/types"

import ClockIcon from "~/assets/icons/ClockIcon"
import Hourglass from "~/assets/icons/Hourglass"

import useMoscowTime from "@/shared/time/useMoscowTime"
import { text16Medium } from "@/utils/fonts"

interface ContestTimingsBriefProps {
  timings: IContestTimings
  tasks: number
}

export default function ContestTimingsBrief({
  timings,
  tasks,
}: ContestTimingsBriefProps) {
  const {
    t,
    i18n: { resolvedLanguage: locale },
  } = useTranslation()

  const start = useMoscowTime(timings.start)

  const duration = useMemo(
    () => formatDuraton({ deltaSeconds: timings.end - timings.start, locale }),
    [locale, timings.end, timings.start]
  )

  const i18n = {
    problemsPerTime: t("pages.contest.description.timings.problems_per_time", {
      count: tasks,
      time: duration,
    }),
  }

  return (
    <Wrapper>
      <Block>
        <ClockIcon />
        {start}
      </Block>

      <Block>
        <Hourglass />
        {i18n.problemsPerTime}
      </Block>
    </Wrapper>
  )
}

const formatDuraton = ({
  deltaSeconds,
  locale,
}: {
  deltaSeconds: number
  locale: string
}): string =>
  Duration.fromObject({ seconds: deltaSeconds }, { locale })
    .rescale()
    .normalize()
    .toHuman({ listStyle: "long" })

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: start;

  gap: 40px;

  width: 100%;
`

const Block = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: start;

  gap: 16px;

  ${text16Medium};

  > svg {
    height: 16px;
    fill: var(--color-text);
  }
`
