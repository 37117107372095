export default function Sloth({ width = 20, height = 20 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33285 11.666C2.71896 11.666 2.22174 11.1688 2.22174 10.5549V2.22155C2.22174 1.60766 2.71896 1.666 3.33285 1.666C3.94674 1.666 4.44396 1.60766 4.44396 2.22155C4.44396 2.22155 4.1073 5.79044 4.44396 8.88655C4.74396 11.6499 6.66619 12.2216 6.66619 12.2216C6.66619 12.8354 3.94674 11.666 3.33285 11.666ZM11.1106 14.4432C10.4967 14.4432 9.99952 13.946 9.99952 13.3321V1.66544C9.99952 1.05155 10.4967 1.10989 11.1106 1.10989C11.7245 1.10989 12.2217 1.05155 12.2217 1.66544C12.2217 1.66544 11.974 4.50322 12.1523 6.83044C12.3301 9.15655 12.2217 13.3321 12.2217 13.3321C12.2217 13.9454 11.7245 14.4432 11.1106 14.4432Z"
        fill="#7A5C44"
      />
      <path
        d="M20 2.22195L0 4.44417V2.22195L20 1.11084V2.22195Z"
        fill="#662113"
      />
      <path
        d="M12.5813 10.2258C12.2296 10.8514 11.824 11.2308 11.3813 11.4442C11.1579 11.467 10.7524 11.397 10.594 10.6425C10.4096 9.76084 10.2229 8.51306 10.2229 6.97917C10.2229 4.67695 10.5568 2.12251 10.5568 2.12251C10.5568 1.6064 11.3413 1.60251 11.7157 1.57195C11.629 1.10973 10.744 1.11084 9.66905 1.11084C8.86183 1.11084 8.41572 1.70751 8.3346 2.38917C8.25294 3.0714 7.89627 5.91528 7.92127 7.94473C7.9346 9.04695 8.00683 10.2564 8.09627 11.287C7.65627 11.1753 7.21572 11.0686 6.78294 11.0142C5.22349 10.8164 3.16905 10.717 2.82238 7.74695C2.54794 5.39584 2.77905 2.67806 2.77905 2.67806C2.77905 2.16195 3.56349 2.03473 3.93794 2.00417C3.85127 1.54195 3.00572 1.49806 1.93238 1.56917C1.12683 1.62251 0.687383 2.03806 0.556827 2.94473C0.346827 4.40528 0.322383 7.7714 0.445716 10.0981C0.514605 11.397 0.717939 16.0758 2.72294 17.8953C3.89405 18.9575 5.88294 19.5853 8.3346 19.4447C10.6596 19.3114 12.5696 18.447 13.639 17.5486C15.4957 15.9892 15.9113 14.8614 15.9885 13.6403C16.1124 11.667 13.3457 8.8664 12.5813 10.2258Z"
        fill="#A78E81"
      />
      <path
        d="M19.4561 12.5941C18.2889 14.3724 15.9689 14.9124 13.4789 13.2785C10.9889 11.6446 10.5605 9.30129 11.7277 7.52295C12.895 5.74462 15.8877 6.0874 17.4966 7.17629C19.1055 8.26518 20.6233 10.8157 19.4561 12.5941Z"
        fill="#A78E81"
      />
      <path
        d="M18.4838 12.8773C17.6505 14.1478 15.6149 14.5351 13.481 13.1351C11.3471 11.7351 11.026 9.80117 11.8599 8.53062C12.6932 7.26007 14.2455 7.4634 16.3794 8.86395C18.5132 10.2645 19.3171 11.6073 18.4838 12.8773Z"
        fill="#E2D2C7"
      />
      <path
        d="M11.426 8.0899C11.1488 8.71546 11.0499 9.38768 11.1532 10.0621C11.9854 10.306 13.1738 10.601 13.7365 10.4838C14.651 10.2938 14.9577 9.47435 14.8232 8.82768C14.6371 7.9299 13.0504 7.41435 11.426 8.0899Z"
        fill="#292F33"
      />
      <path
        d="M12.9938 9.86981C13.3583 9.86981 13.6538 9.57432 13.6538 9.20981C13.6538 8.8453 13.3583 8.5498 12.9938 8.5498C12.6293 8.5498 12.3338 8.8453 12.3338 9.20981C12.3338 9.57432 12.6293 9.86981 12.9938 9.86981Z"
        fill="#F5F8FA"
      />
      <path
        d="M19.0586 13.0999C18.5953 13.6032 18.0181 13.9616 17.3581 14.1355C16.8031 13.4694 16.0598 12.496 15.9425 11.9333C15.7525 11.0188 16.3825 10.4116 17.0292 10.2771C17.9275 10.091 19.032 11.341 19.0586 13.0999Z"
        fill="#292F33"
      />
      <path
        d="M17.4127 12.7678C17.7772 12.7678 18.0727 12.4723 18.0727 12.1078C18.0727 11.7432 17.7772 11.4478 17.4127 11.4478C17.0482 11.4478 16.7527 11.7432 16.7527 12.1078C16.7527 12.4723 17.0482 12.7678 17.4127 12.7678Z"
        fill="#F5F8FA"
      />
      <path
        d="M15.5789 12.4857C15.1789 13.0957 14.36 13.2652 13.7506 12.8652C13.1406 12.4652 12.9712 11.6463 13.3712 11.0368C13.7712 10.4268 14.2278 10.809 14.8373 11.209C15.4467 11.609 15.9789 11.8757 15.5789 12.4857Z"
        fill="#292F33"
      />
      <path
        d="M15.5404 13.9588C15.5271 13.9588 15.5132 13.9576 15.4998 13.956C13.0115 13.5926 12.0015 11.6949 11.9598 11.6138C11.8898 11.4782 11.9426 11.311 12.0787 11.2404C12.2154 11.1682 12.3821 11.2232 12.4532 11.3577C12.4626 11.3749 13.3826 13.0849 15.5804 13.406C15.7321 13.4282 15.8371 13.5693 15.8154 13.721C15.7948 13.8593 15.6765 13.9588 15.5404 13.9588Z"
        fill="#292F33"
      />
    </svg>
  )
}
