import styled from "styled-components"

import { ThemeT } from "@/store/slices/common/types"

import { useTheme } from "@/store/hooks"

export default function AwardCup() {
  const theme = useTheme()

  return (
    <CupSVG
      width="90"
      height="90"
      viewBox="0 0 90 90"
      xmlns="http://www.w3.org/2000/svg"
      $theme={theme}
    >
      <path d="M13.3059 12.5H28.3059C31.0659 12.5 32.9984 12.24 32.9984 15V10C32.9984 7.24 31.0659 5 28.3059 5H8.30586C3.30586 5 -0.65164 10.3125 0.80586 17.5C0.80586 17.5 5.28336 40.9375 5.59836 42.395C6.43086 46.25 10.9084 50 15.7009 50H33.0959C35.8584 50 32.9984 45.26 32.9984 42.5V37.5C32.9984 40.26 31.0659 42.5 28.3059 42.5H18.3059C15.5459 42.5 13.7234 39.895 13.3059 37.5C12.8884 35.105 9.34586 19.1675 9.34586 19.1675C8.51336 14.375 10.5434 12.5 13.3059 12.5ZM77.8359 12.5H62.8359C60.0759 12.5 55.4984 12.24 55.4984 15V10C55.4984 7.24 60.0759 5 62.8359 5H82.8359C87.8359 5 91.7959 10.3125 90.3359 17.5C90.3359 17.5 86.2534 41.0475 85.9084 42.5C85.0234 46.25 80.8034 50 75.9084 50H58.0434C55.2834 50 55.4984 45.26 55.4984 42.5V37.5C55.4984 40.26 60.0759 42.5 62.8359 42.5H72.8359C75.5959 42.5 77.4209 39.895 77.8359 37.5C78.2509 35.105 81.7959 19.1675 81.7959 19.1675C82.6259 14.375 80.5984 12.5 77.8359 12.5ZM52.5784 55C52.5784 37.605 45.8059 55 45.8059 55C45.8059 55 38.3059 37.605 38.3059 55C38.3059 72.395 30.0784 80 30.0784 80H60.8084C60.8059 80 52.5784 72.395 52.5784 55Z" />
      <path d="M73.3086 16.4427C73.3086 33.3802 56.3836 61.9227 45.8086 61.9227C35.2311 61.9227 18.3086 33.3802 18.3086 16.4427C18.3086 3.4552 20.8086 0.645198 25.8086 0.645198C29.2436 0.645198 40.8711 0.577698 45.8086 0.577698L63.3086 0.575198C70.6011 0.572698 73.3086 2.2852 73.3086 16.4427Z" />
      <path d="M68 82.5C68 85.26 68.5675 87.5 65.8075 87.5H25.8075C23.045 87.5 23 85.26 23 82.5V80C23 77.24 25.91 75 28.515 75H63.3075C65.9125 75 68 77.24 68 80V82.5Z" />
      <path d="M73 86.5625C73 88.4625 73.4125 90 69.87 90H21.745C18.515 90 18 88.4625 18 86.5625V85.9375C18 84.0375 19.845 82.5 21.745 82.5H69.87C71.7675 82.5 73 84.0375 73 85.9375V86.5625Z" />
    </CupSVG>
  )
}

const CupSVG = styled.svg<{ $theme: ThemeT }>`
  path:nth-child(1) {
    fill: ${({ $theme }) => ($theme === "dark" ? "#ffb84d" : "#ffac33")};
  }

  path:nth-child(2) {
    fill: ${({ $theme }) => ($theme === "dark" ? "#ffd467" : "#ffcc4d")};
  }

  path {
    fill: ${({ $theme }) => ($theme === "dark" ? "#703d2e" : "#c1694f")};
  }
`
