export default function TelegramIcon({
  width = 24,
  height = 24,
  fill = "white",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.95617 11.3922C7.73382 8.96891 11.5865 7.37133 13.5142 6.59946C19.0181 4.39561 20.1618 4.01278 20.9072 4.00013C21.0712 3.99735 21.4378 4.03647 21.6752 4.22196C21.8757 4.37858 21.9309 4.59016 21.9573 4.73865C21.9837 4.88715 22.0166 5.22542 21.9904 5.48974C21.6922 8.50665 20.4016 15.8279 19.745 19.2069C19.4672 20.6366 18.9162 20.9426 18.3866 20.9896C17.2357 21.0915 16.3657 20.4307 15.251 19.7273C13.5066 18.6265 12.8655 18.33 11.1723 17.2559C9.21554 16.0145 10.2831 15.3648 11.3983 14.2498C11.6901 13.958 16.7972 9.41227 16.8953 9.00946C16.9076 8.95908 16.7396 8.45538 16.6237 8.35622C16.5078 8.25706 16.3368 8.29097 16.2133 8.31794C16.0384 8.35616 13.2518 10.1293 7.85356 13.6372C7.0626 14.1601 6.34617 14.4149 5.70427 14.4015C4.99663 14.3868 3.63541 14.0163 2.62348 13.6997C1.38232 13.3113 0.924159 13.1284 1.01006 12.4687C1.0548 12.1252 1.37017 11.7663 1.95617 11.3922Z"
      />
    </svg>
  )
}
