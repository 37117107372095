export default function KeyIcon() {
  return (
    <svg fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 1.667c.221 0 .433.087.59.244l2.5 2.5a.833.833 0 01.243.589v5a.833.833 0 01-.244.59l-2.5 2.5a.833.833 0 01-.589.243h-3.822L6.423 18.09a.833.833 0 01-.59.244H2.5a.833.833 0 01-.833-.833v-3.334c0-.22.087-.433.244-.589l4.756-4.756V5c0-.221.087-.433.244-.59l2.5-2.5A.833.833 0 0110 1.668h5zm-.345 1.666h-4.31L8.333 5.345v3.821a.833.833 0 01-.244.59l-4.756 4.756v2.154h2.155l4.756-4.755a.833.833 0 01.59-.245h3.82l2.013-2.011v-4.31l-2.012-2.012zM13.333 5a1.667 1.667 0 110 3.333 1.667 1.667 0 010-3.333z"
        fill="#8e8e8e"
        fillRule="evenodd"
      />
    </svg>
  )
}
