interface IEJudgeProps {
  width?: number
  height?: number
  className?: string
}

const ejudgeIcon = "/assets/images/ejudge.png"

export default function EJudge({
  width = 104,
  height = 104,
  className = "",
}: IEJudgeProps) {
  return (
    <img
      src={ejudgeIcon}
      alt="ejudge"
      width={width}
      height={height}
      className={className}
    />
  )
}
