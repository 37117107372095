import { useTheme } from "@/store/hooks"

import { SilverSVG } from "./styled"

export default function RankSilverOne() {
  const theme = useTheme()

  return (
    <SilverSVG
      width="158"
      height="48"
      viewBox="0 0 158 48"
      xmlns="http://www.w3.org/2000/svg"
      $theme={theme}
    >
      <rect width="158" height="48" rx="8" />
      <path d="M84.4617 33.1318C84.2251 33.1318 83.9884 33.0584 83.7851 32.9135L78.9988 29.4821L74.2142 32.9135C73.8059 33.2051 73.2593 33.2051 72.8543 32.9084C72.4494 32.6151 72.2794 32.0935 72.4294 31.6186L74.2142 25.859L69.4729 22.5176C69.0696 22.221 68.903 21.6977 69.0563 21.2211C69.2113 20.7461 69.6529 20.4228 70.1545 20.4195L76.0274 20.4111L77.8972 14.7949C78.0556 14.32 78.4989 14 79.0005 14C79.5004 14 79.9454 14.32 80.1037 14.7949L81.9419 20.4111L87.8448 20.4195C88.3464 20.4228 88.7897 20.7461 88.943 21.2211C89.098 21.6977 88.9297 22.2193 88.5264 22.5176L83.7834 25.859L85.5683 31.6186C85.7216 32.0952 85.5483 32.6151 85.145 32.9084C84.94 33.0584 84.7 33.1318 84.4617 33.1318Z" />
    </SilverSVG>
  )
}
