interface IGlobeIconProps {
  width?: number
  height?: number
  className?: string
}

export default function GlobeIcon({
  width = 20,
  height = 20,
  className = "",
}: IGlobeIconProps) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0003 1.6665C14.6027 1.6665 18.3337 5.39746 18.3337 9.99984C18.3337 14.6022 14.6027 18.3332 10.0003 18.3332C5.39795 18.3332 1.66699 14.6022 1.66699 9.99984C1.66699 5.39746 5.39795 1.6665 10.0003 1.6665ZM12.4774 10.8338H7.52329C7.61574 12.5034 7.98294 14.0234 8.54476 15.147C9.068 16.1935 9.62713 16.6665 10.0003 16.6665C10.3735 16.6665 10.9327 16.1935 11.4559 15.147C12.0177 14.0234 12.3849 12.5034 12.4774 10.8338ZM16.6153 10.834L14.1464 10.8342C14.0455 12.8625 13.5811 14.6767 12.8853 16.0125C14.8838 15.0512 16.3296 13.1228 16.6153 10.834ZM5.85428 10.8342L3.38534 10.834C3.67103 13.1227 5.11693 15.0511 7.11586 16.0119C6.46311 14.7601 6.01408 13.0874 5.87742 11.2119L5.85428 10.8342V10.8342ZM7.11536 3.98714L7.09357 3.99855C5.10598 4.96303 3.66968 6.88583 3.38525 9.16636L5.85424 9.16629C5.95497 7.1377 6.41944 5.32312 7.11536 3.98714ZM10.0003 3.33317L9.93258 3.3381C9.56147 3.3908 9.03722 3.86771 8.54476 4.85264C7.98285 5.97646 7.61562 7.49678 7.52324 9.16669H12.4774C12.385 7.49678 12.0178 5.97646 11.4559 4.85264C10.9326 3.80615 10.3735 3.33317 10.0003 3.33317ZM12.8848 3.98778L12.9036 4.02254C13.5894 5.35522 14.0467 7.1559 14.1464 9.16687L16.6154 9.16652C16.33 6.8774 14.884 4.94869 12.8848 3.98778Z"
      />
    </svg>
  )
}
