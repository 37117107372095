export default function ServerIcon({ width = 16, height = 16 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6668 2.5C13.7714 2.5 14.6668 3.39543 14.6668 4.5V5.83333C14.6668 6.9379 13.7714 7.83333 12.6668 7.83333H12.0002V9.16667H12.6668C13.7714 9.16667 14.6668 10.0621 14.6668 11.1667V12.5C14.6668 13.6046 13.7714 14.5 12.6668 14.5H3.3335C2.22893 14.5 1.3335 13.6046 1.3335 12.5V11.1667C1.3335 10.0621 2.22893 9.16667 3.3335 9.16667H4.00016V7.83333H3.3335C2.22893 7.83333 1.3335 6.9379 1.3335 5.83333V4.5C1.3335 3.39543 2.22893 2.5 3.3335 2.5H12.6668ZM12.6668 10.5H3.3335C2.96531 10.5 2.66683 10.7985 2.66683 11.1667V12.5C2.66683 12.8682 2.96531 13.1667 3.3335 13.1667H12.6668C13.035 13.1667 13.3335 12.8682 13.3335 12.5V11.1667C13.3335 10.7985 13.035 10.5 12.6668 10.5ZM4.66683 11.1667C5.03502 11.1667 5.3335 11.4651 5.3335 11.8333C5.3335 12.2015 5.03502 12.5 4.66683 12.5C4.29864 12.5 4.00016 12.2015 4.00016 11.8333C4.00016 11.4651 4.29864 11.1667 4.66683 11.1667ZM10.6668 7.83333H5.3335V9.16667H10.6668V7.83333ZM12.6668 3.83333H3.3335C2.96531 3.83333 2.66683 4.13181 2.66683 4.5V5.83333C2.66683 6.20152 2.96531 6.5 3.3335 6.5H12.6668C13.035 6.5 13.3335 6.20152 13.3335 5.83333V4.5C13.3335 4.13181 13.035 3.83333 12.6668 3.83333ZM4.66683 4.5C5.03502 4.5 5.3335 4.79848 5.3335 5.16667C5.3335 5.53486 5.03502 5.83333 4.66683 5.83333C4.29864 5.83333 4.00016 5.53486 4.00016 5.16667C4.00016 4.79848 4.29864 4.5 4.66683 4.5Z"
        fill="#909090"
      />
    </svg>
  )
}
