interface IFilterIconProps {
  width?: number
  height?: number
}

export default function FilterIcon({
  width = 18,
  height = 18,
}: IFilterIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 10.7184L1.61725 2.65266C1.29952 2.15336 1.65818 1.5 2.25 1.5H15.75C16.3418 1.5 16.7005 2.15336 16.3827 2.65266L11.25 10.7184V14.25C11.25 14.5341 11.0895 14.7938 10.8354 14.9208L7.83541 16.4208C7.33673 16.6702 6.75 16.3075 6.75 15.75V10.7184ZM3.61615 3L8.13264 10.0973C8.20922 10.2177 8.2499 10.3574 8.2499 10.5V14.5365L9.7499 13.7865V10.5C9.7499 10.3574 9.79057 10.2177 9.86715 10.0973L14.3836 3H3.61615Z"
      />
    </svg>
  )
}
