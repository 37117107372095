interface IBookmarkIconProps {
  width?: number
  height?: number
  className?: string
}

export default function BookmarkIcon({
  width = 16,
  height = 16,
  className = "",
}: IBookmarkIconProps) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.7672 14.5058C3.33475 14.8765 2.66667 14.5692 2.66667 13.9997V3.33301C2.66667 2.22844 3.5621 1.33301 4.66667 1.33301H11.3333C12.4379 1.33301 13.3333 2.22844 13.3333 3.33301V13.9997C13.3333 14.5692 12.6653 14.8765 12.2328 14.5058L8 10.8777L3.7672 14.5058ZM12 12.5502V3.33301C12 2.96482 11.7015 2.66634 11.3333 2.66634H4.66667C4.29848 2.66634 4.00001 2.96482 4.00001 3.33301V12.5502L7.56614 9.4935C7.8158 9.27951 8.18421 9.27951 8.43387 9.4935L12 12.5502Z"
      />
    </svg>
  )
}
