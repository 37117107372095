interface IMinusMarkerProps {
  width?: number
  height?: number
}

export default function MinusMarker({
  width = 20,
  height = 20,
}: IMinusMarkerProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16666 9.16699H15.8333C16.2936 9.16699 16.6667 9.54009 16.6667 10.0003C16.6667 10.4606 16.2936 10.8337 15.8333 10.8337H4.16666C3.70642 10.8337 3.33333 10.4606 3.33333 10.0003C3.33333 9.54009 3.70642 9.16699 4.16666 9.16699Z"
      />
    </svg>
  )
}
