export default function GoogleIcon({ width = 18, height = 18 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.64 9.2C17.64 8.563 17.583 7.949 17.476 7.36H9V10.841H13.844C13.635 11.966 13.001 12.919 12.048 13.558V15.816H14.956C16.658 14.249 17.64 11.942 17.64 9.201V9.2Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00296 18C11.433 18 13.47 17.194 14.959 15.82L12.05 13.56C11.244 14.1 10.214 14.42 9.00296 14.42C6.65896 14.42 4.67496 12.836 3.96696 10.709H0.959961V13.041C2.43996 15.983 5.48496 18 9.00296 18Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.964 10.712C3.784 10.172 3.682 9.59501 3.682 9.00201C3.682 8.40901 3.784 7.83201 3.964 7.29201V4.96001H0.957C0.347 6.17501 0 7.55001 0 9.00201C0 10.454 0.348 11.829 0.957 13.044L3.964 10.712Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00296 3.58C10.324 3.58 11.511 4.034 12.443 4.925L15.025 2.345C13.464 0.891 11.428 0 9.00196 0C5.48496 0 2.43996 2.017 0.959961 4.958L3.96696 7.29C4.67496 5.163 6.65896 3.58 9.00296 3.58Z"
        fill="#EA4335"
      />
    </svg>
  )
}
