import { useMemo } from "react"

import { useLocation } from "react-router-dom"

import { ILinkDetails } from "./types"

export default function useActiveLink(links: ILinkDetails[]) {
  const { pathname } = useLocation()

  return useMemo(() => {
    return links.findIndex(
      link => pathname === link.href || pathname === link.href + "/"
    )
  }, [links, pathname])
}
