import React from "react"

interface GoogleBubbleProps {
  onClick?: React.MouseEventHandler<SVGElement>
  className?: string
}

export default function GoogleBubble({
  onClick = undefined,
  className = undefined,
}: GoogleBubbleProps) {
  return (
    <svg
      fill="none"
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      <rect height="30" width="30" fill="#fff" rx="6.818" />
      <rect
        height="29.167"
        width="29.167"
        rx="6.402"
        stroke="#000"
        strokeOpacity=".1"
        strokeWidth=".833"
        x=".417"
        y=".417"
      />
      <g clipPath="url(#a)" fillRule="evenodd">
        <path
          d="M22.2 15.167c0-.53-.047-1.042-.137-1.533H15v2.9h4.037A3.455 3.455 0 0117.54 18.8v1.881h2.423c1.419-1.305 2.237-3.228 2.237-5.512z"
          fill="#4285F4"
        />
        <path
          d="M15.003 22.5c2.025 0 3.723-.672 4.964-1.817L17.542 18.8c-.671.45-1.53.717-2.539.717-1.953 0-3.606-1.32-4.196-3.093H8.3v1.944a7.497 7.497 0 006.702 4.132z"
          fill="#34A853"
        />
        <path
          d="M10.803 16.427c-.15-.45-.235-.931-.235-1.425 0-.495.085-.975.235-1.425v-1.944H8.297a7.505 7.505 0 000 6.737l2.506-1.943z"
          fill="#FBBC05"
        />
        <path
          d="M15.003 10.483c1.101 0 2.09.379 2.867 1.121l2.152-2.15C18.72 8.242 17.024 7.5 15.002 7.5a7.497 7.497 0 00-6.701 4.132l2.506 1.943c.59-1.772 2.243-3.092 4.196-3.092z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M0 0h15v15H0z" fill="#fff" transform="translate(7.5 7.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
